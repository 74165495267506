.main-content {
    // General styling for contentblock
    .requestedarticle .article-content {
        border-bottom: 1px solid $medium-light-grey;
        padding-top: 20px;
        position: relative;
        background: $white;

        .intro {
            padding: 0 20px;
        }
        .maincontentblocks {
            padding: 0 20px;
        }
    }

    .intro p {
        font-weight: bold;
    }

    .contentblock ul {
        margin-left: 20px;
    }

    // Styling for specific contentblocks
    .contentblock.smallphototext ul {
        list-style-position: inside;
        margin-left: 0;

        ul {
            margin-left: 20px;
        }
    }

    .contentblock.smallphototext .photocontainer,
    .contentblock.smallphototext > .photo {
        float: left;
        margin: 5px 15px 5px 0;
        max-width: 33%;
    }

    .contentblock.textsmallphoto .photocontainer,
    .contentblock.textsmallphoto > .photo {
        float: right;
        margin: 5px 0 5px 15px;
        max-width: 33%;
    }

    .contentblock.smallphototext .contentblockcontent.richtext table,
    .contentblock.textsmallphoto .contentblockcontent.richtext table {
        width: 60%;
    }

    .contentblock.youtube {
        h2 {
            @include x-rem(margin-bottom, 1);
        }
    }

    .contentblock.photophoto {
        @include grid-row();

        .photocontainer {
            $column-gutter: 0;
            @include grid-column(12);

            &.photocontainer_second {
                margin-top: 20px;
            }
        }
    }

    .contentblock.photo {
        img {
            width: 100%;
        }
    }

    .contentblockcontent.photo {
        img {
            width: 100%;
        }
    }

    .contentblock.photo,
    .contentblock.photophoto {
        @include x-rem(margin-bottom, 2);
    }

    .contentblock.compositeform {
        background-color: $light-gray;
        padding-top: 20px;

        h2 {
            margin-top: 0;
        }

        textarea {
            height: 100px;
        }

        p {
            margin-bottom: 0;
        }

        // Ajax call retrieves article contentblocks when form has been succesfully sent
        .article-content {
            border: 0;
            background: transparent;
        }

        .contentblocks {
            border: 0;

            .contentblock {
                padding: 0;
            }

            .firstcb p {
                font-weight: normal;
            }
        }
    }

    // Styling for general contentblockcontents
    .emptycontentblockcontent {
        display: none;
    }

    .contentblockcontent.photolabel {
        font-style: italic;
        margin-top: 10px;
    }

    .contentblock.firstcb h2 {
        margin-top: 0;
    }

    .contentblock.lastcb {
        padding-bottom: 30px;

        &.compositeform {
            padding-bottom: 20px;
        }
    }

    .contentblockcontent.richtext {
        table {
            width: 100%;
            margin-top: 12px;

            thead td {
                background-color: $lighter-gray;
                font-weight: bold;
            }

            td {
                border: 1px solid $border-color;
                padding: 15px;
                color: $medium-dark-gray;

                label {
                    display: none;
                }
            }
        }

        blockquote {
            @include x-rem(line-height, 1.5);
            font-style: italic;
            font-weight: normal;
            padding-top: 0;
            padding-bottom: 0;
            color: $medium-dark-gray;
            border: 0;
            text-align: center;
            quotes: '\201C''\201D''\2018''\2019';
            position: relative;
            margin-top: 15px;
            margin-bottom: 15px;

            &::before,
            &::after {
                @include x-rem(font-size, 5);
                position: absolute;
                top: 20px;
                left: -30px;
                content: open-quote;
                color: $dark-gray;
                font-style: normal;
            }

            &::after {
                left: auto;
                right: -30px;
                top: 100%;
                content: close-quote;
            }
        }
    }

    @media #{$small-only} {
        .contentblockcontent.richtext {
            table {
                thead {
                    display: none;
                }

                td {
                    display: block;
                    text-align: left;
                    border-top: 0;
                    padding-left: 50%;
                    position: relative;

                    &:first-child {
                        border-top: 2px solid $border-color;
                    }

                    label {
                        position: absolute;
                        top: 15px;
                        left: 15px;
                        display: block;
                        width: 45%;
                    }
                }

                tr.odd td {
                    background-color: $light-gray;
                }
            }

        }

        .contentblock.smallphototext .photocontainer,
        .contentblock.textsmallphoto .photocontainer,
        .contentblock.smallphototext > .photo,
        .contentblock.textsmallphoto > .photo {
            max-width: 100%;
            width: 100%;
        }
    }

    @include respond-min($medium-bp) {
        .requestedarticle .article-content {
            border: 1px solid $medium-light-grey;
            border-top: 0;
        }

        .contentblock.photophoto .photocontainer {
            @include grid-column(6);

            &.photocontainer_first {
                padding-right: 10px;
            }

            &.photocontainer_second {
                padding-left: 10px;
                margin-top: 0;
            }
        }
    }
}
