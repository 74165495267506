.autocomplete-suggestions {
    top: 49px;
    border: 1px solid #cdcdcd;
    max-height: 450px;
    overflow: auto;
    background: $white;
    width: 100%;
    height: auto;
    line-height: 1em;
    border-radius: 0 0 1px 1px;

    .autocomplete-suggestion {
        padding: 20px;
        border-top: 1px solid #cdcdcd;
        margin-top: -1px;

        &:hover {
            background-color: $light-gray;
            cursor: pointer;
        }

        & > strong {
            color: $black;
        }
    }
}

.header {
    // Header search form
    #search_form {
        @include grid-column(12);
        z-index: 9998;
        background-color: $white;
        width: 100%;
        padding: 0;
        top: 72px;
        border-bottom: 1px solid $medium-light-grey;
        display: none;

        &.open {
            display: block;
        }

        &,
        label {
            display: none;
        }

        .formfield {
            @include inline-block;

            &.text_field input {
                @include border-radius(3px 0 0 3px);
                @include box-shadow(none);
                background: url('/assets/images/icons/search_small.png') no-repeat;
                background-position: 15px 15px;
                background-size: 15px;
                width: 100%;
                border-color: $medium-light-grey;
                border-right: 1px solid $medium-light-grey;

                &:focus {
                    @include box-shadow(none);
                    border-color: $primary-color;
                }
            }

            &.submit_field input {
                @include border-radius(0 3px 3px 0);
                border-left-width: 0 !important;
            }
        }

        @media #{$small-up} {
            padding: 2px;
            position: absolute;
            top: 65px;
        }

        @media #{$header-breakpoint} {
            padding: 3px;
            top: 94px;
        }

        @include respond-min($large-bp) {
            padding: 16px 20px;
            position: relative;
            top: 0;
            z-index: 1;
            border-width: 0;
        }

        &.filter-open {
            display: block;
        }

        & > form {
            @include inline-block;
            @include grid-row(nested);

            .queryfield {
                @include inline-block;
                @include grid-column(9, $collapse: true);
            }

            .submit_field {
                @include inline-block;
                @include grid-column(3, $collapse: true);
                position: relative;
            }

            @media #{$small-only} {
                .queryfield {
                    width: 70%;
                }

                .submit_field {
                    width: 30%;
                }
            }

            label {
                display: none;
            }

            input {
                @include x-rem(font-size, 1.05);
                height: 49px;
                margin-bottom: 0;
                width: 100%;
            }

            .text_field input {
                @include inline-block;
                border-radius: 3px 0 0 3px;
                border-right-width: 0;
                background-color: $white;

                @include respond-min($large-bp) {
                    @include icon(search);
                    @include x-rem(padding-left, 3);
                    padding-top: 0;
                    padding-bottom: 0;
                    line-height: 49px;
                }

                &,
                html.ie9 & {
                    background-color: $white !important;
                }
            }

            .submit_field input {
                @include facilicom-button;
                @include inline-block;
                border-radius: 0 3px 3px 0;
                border-top-color: $medium-light-grey !important;
                border-bottom-color: $medium-light-grey !important;
                border-right-color: $medium-light-grey !important;
            }
        }
    }
}

@include respond-min($header-bp) {
    .header {
        #search_form {
            display: none;
        }
    }
}

// Desktop style
@include respond-min($large-bp) {
    $height: 90px;

    .header {
        #search_form {
            @include grid-column(6, $collapse: true);
            display: block;
            padding: 0 20px;
            height: $height;
            position: static;
            width: 540px;
            background-color: #f3f3f3;
            border-left: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;

            .formfield.text_field input {
                background-color: $white;
            }

            .formfield.submit_field input {
                background-color: #f8f8f8;
            }

            & > form {
                height: $height;
                line-height: $height;
                position: relative;

                .autocomplete-suggestions {
                    width: 69.1%;
                    top: 49px + 20px + 3px;
                }
            }
        }
    }
}
