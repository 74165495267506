// main content + sidebar
.main-content,
.sidebar {
    $column-gutter: 0;
    @include grid-column(12);

    p {
        @include x-rem(line-height, 1.4);
    }

    a {
        text-decoration: underline;
    }
}

.main-content {
    @include x-rem(margin-bottom, 3);

    h1 {
        @include x-rem(line-height, 1.6);
        @include x-rem(font-size, 1.6);
        padding: 0 20px;
        margin-top: 0;
        margin-bottom: 0;
    }

    h2 {
        @include x-rem(font-size, 1);
        @include x-rem(margin-top, 2);
        @include x-rem(margin-bottom, .24);
        color: $primary-color;
        font-weight: bold;
    }

    ul {
        margin-top: 0;
        margin-left: 0;
    }

    p {
        @include x-rem(margin-bottom, 1);
    }

    .article-header {
        position: relative;
        background: $white;
        background: rgba($white, .75);
        padding: 15px 20px;
    }

    .pager {
        margin-top: 20px;

        .pagenumbers,
        .previouspage,
        .nextpage {
            @include inline-block();
            text-decoration: none;
        }

        .previouspage,
        .nextpage {
            &:hover {
                text-decoration: underline;
            }
        }

        .previouspage {
            margin-right: 10px;
        }

        .nextpage {
            margin-left: 20px;
        }

        ul {
            margin: 0;
        }

        li {
            @include inline-block;
            list-style-type: none;
            margin-right: 10px;

            a {
                display: block;
                background-color: $light-gray;
                width: 38px;
                height: 38px;
                text-align: center;
                line-height: 38px;
                text-decoration: none;

                &.active {
                    background-color: $dark-gray;
                }

                &:hover {
                    background-color: $white;
                    border: 1px solid #efefef;
                    line-height: 36px;
                }
            }
        }

        @media #{$small-only} {
            padding-bottom: 20px;
            border-bottom: 1px solid $border-color;

            .pagenumbers {
                display: none;
            }

            a.nextpage,
            a.previouspage {
                margin: 0 20px 5px;
                padding-left: 20px;
                display: block;
                background-color: $light-gray;
                height: 38px;
                text-align: left;
                line-height: 38px;

                &:hover {
                    background-color: $white;
                    border: 1px solid #efefef;
                    line-height: 36px;
                }
            }

            a.nextpage {
                .arrow-right {
                    @include icon(arrow_right_gray);
                    display: block;
                    margin: 15px 20px;
                    float: right;
                }
            }
        }
    }
}

.background-image {
    position: absolute;
    top: 64px;
    left: 0;
    width: 100%;

    img {
        width: 100%;
    }
}


@media #{$small-only} {
    .background-image {
        img {
            width: auto;
            max-height: 140px;
        }
    }
}

@media #{$medium-only} {
    section.row {
        padding: 0 20px;
    }

    .main-content,
    .sidebar {
        float: none;
        clear: both;
        margin-left: auto;
        margin-right: auto;
    }
}

// Tablet style
@include respond-min($medium-bp) {
    .main-content,
    .sidebar {
        $column-gutter: (1.875 * $base-font-size);
    }

    .main-content {
        h1 {
            @include x-rem(font-size, 2);
            @include x-rem(line-height, 2);
            width: 100%;
            padding: 0;
        }
    }

    .sidebar h2 {
        @include x-rem(font-size, 2);
        @include x-rem(line-height, 2);
    }

    .background-image {
        top: 94px;
    }
}

// Desktop style
@include respond-min($large-bp) {
    .main-content,
    .sidebar {
        @include x-rem(margin-bottom, 4);
        @include x-rem(margin-top, 7);
    }

    .main-content {
        @include grid-column(9, $collapse:true);
    }

    .sidebar {
        @include grid-column(3, $collapse:true);
        padding-left: 20px;
    }

    .row.media .main-content {
        @include grid-column(12);
    }

    .background-image {
        top: 145px;
        max-height: 280px;
        overflow: hidden;
    }
}
