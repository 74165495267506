$division-name: 'facilicom' !default;

// Colors
$primary-color: #002469; // blue
$secondary-color: #f00; // red

$black: #404040;
$real-black: #000;
$darker-gray: #909090;
$dark-gray: #c7c7c7;
$heavy-dark-gray: #5a5a5a;
$medium-dark-gray: #6b6b6b;
$medium-gray: #e0e0e0;
$light-gray: #eee;
$lighter-gray: #f5f5f5;
$medium-light-grey: #ddd;
$white: #fff;

// Social media colors
$twitter-blue: #00aced;
$linkedin-blue: #007bb6;
$facebook-blue: #3b5998;
$youtube-red: #df2a25;
$rss-orange: #f38b17;

$border-color: $medium-gray;
$block-background: $medium-gray;

$medium-bp: 641px;
$header-bp: 720px;
$large-bp: 1025px;
$xlarge-bp: 1441px;
$xxlarge-bp: 1921px;

// Small screens
$screen: 'only screen';

$small-up: $screen;
$small-only: "#{$screen} and (max-width: 640px)";

// Medium screens
$medium-up: "#{$screen} and (min-width:641px)";
$medium-only: "#{$screen} and (min-width:641px) and (max-width:1024px)";

$header-breakpoint: "#{$screen} and (min-width:720px)";

// Large screens
$large-up: "#{$screen} and (min-width:1025px)";
$large-only: "#{$screen} and (min-width:1025px) and (max-width:1440px)";

// Extra large screens
$xlarge-up: "#{$screen} and (min-width:1441px)";
$xlarge-only: "#{$screen} and (min-width:1441px) and (max-width:1920px)";

// And above
$xxlarge-up: "#{$screen} and (min-width:1921px)";
//$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})";

$row-width: 992px;

$rem-base: 16px;
$body-font-family: Arial, "Helvetica Neue", "Helvetica", Helvetica, sans-serif;
$header-font-family: $body-font-family;
$header-font-color: $secondary-color;
$header-font-weight: normal;
$h1-font-size: rem-calc(30);
$h2-font-size: 1rem;
