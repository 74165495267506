.articlelist.discussion-overview {
    .new-discussion-button-block-wrapper {
        border: 1px solid $border-color;
        border-top: 0;
        padding: 20px;
        background: $white;

        .new-discussion-button-block {
            padding: 25px 15px;
            background: $medium-gray;
            color: $primary-color;
            font-size: 24px;
            position: relative;

            .button-wrapper {
                padding-left: 190px;
                padding-right: 20px;
                width: 100%;
                position: absolute;
                left: 0;
                top: 18px;
            }

            a {
                @include facilicom-button();
                width: 100%;
                font-size: 14px;
            }
        }
    }

    .start-dicussion-form-wrapper {
        background: $white;
        border: 1px solid $border-color;
        border-top: 0;
        padding: 20px;
        display: none;

        // Success page via ajax
        .article-content .contentblock {
            &.lastcb {
                padding-bottom: 0;
            }

            p {
                margin-bottom: 0;
            }
        }

        .article_wrapper {
            margin: 0 -20px -20px;
            border: 0;
            border-top: 1px solid $border-color;
            margin-top: 20px;

            h2 {
                font-size: 18px;
            }
        }
        // End of succes page via ajax
        form {
            background: $medium-gray;
            padding: 20px;
        }

        h2 {
            font-size: 24px;
            font-weight: normal;
            margin-top: 0;
            margin-bottom: 5px;
        }

        h3 {
            font-size: 14px;
            font-weight: bold;
            margin-top: 15px;
            color: $primary-color;
        }

        .submit_field input {
            @include facilicom_button();
        }

        textarea {
            height: 115px;
        }
    }

    .article_wrapper .article {
        padding-right: 0;

        .description {
            padding: 19px;
            height: auto;

            h2 {
                padding-top: 0;
            }

            p {
                margin-bottom: 0;
            }
        }

        .bottom {
            width: 100%;
            background: $light-gray;
            position: relative;

            .comments-count,
            .reply {
                @include inline-block;
                padding: 9px 20px;
            }

            .comments-count {
                border-right: 1px solid $white;

                a {
                    color: $medium-dark-gray;
                    text-decoration: none;
                }

                .icon {
                    @include inline-image(discussion_gray) {
                        width: 17px;
                        height: 17px;
                    };
                }
            }

            .reply {
                a {
                    text-decoration: none;
                    color: $medium-dark-gray;
                }

                .icon {
                    @include inline-image(discussion_reply) {
                        width: 16px;
                        height: 17px;
                    };
                }
            }

            .date {
                position: absolute;
                right: 0;
                top: 0;
                padding: 10px 20px;
                border-left: 1px solid $white;
            }

            .icon {
                @include inline-block;
                margin-right: 8px;
            }
        }
    }
}
