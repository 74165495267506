.header {
    & > div {
        $height: 65px;

        .small_navigation {
            @include reset-list-style;
            display: block;
            float: right;
            height: $height - 2px;
            line-height: $height -2px;
            padding: 0;
            margin: 0;

            & li {
                @include inline-block(top);
                padding: 0;
                margin: 0;
                vertical-align: middle;

                a {
                    @include inline-block(top);
                    padding: 0 1em;
                    border-left: 1px solid $border-color;
                    height: $height;
                    line-height: $height;

                    &.active,
                    &:hover {
                        background-color: $white;
                    }

                    &.active {
                        @include single-box-shadow(0, 1px, 0, 0, $white);
                    }

                    &.search .icon {
                        background: url('/assets/images/icons/search_small.png');
                        background-position: 16px 16px;
                        background-size: 16px;
                        width: 16px;
                        height: 16px;
                        @include inline-block(middle);
                    }

                    &.main .icon {
                        background: url('/assets/images/icons/menu_small.png');
                        background-position: 0 16px;
                        background-size: 30px;
                        width: 30px;
                        height: 16px;
                        @include inline-block(middle);
                    }

                    .icon {
                        @include inline-block(middle);
                    }

                    .label {
                        display: none;
                    }
                }
            }
        }
    }
}

// Tablet and up
@include respond-min($header-bp) {
    .header {
        & > div {
            $height: 93px;

            .small_navigation {
                height: $height - 2px;
                line-height: $height -2px;

                & li {
                    a {
                        padding: 0 2em;
                        height: 91px;
                        line-height: $height - 2px;

                        &.corporate {
                            color: $darker-gray;

                            .icon {
                                background: url('/assets/images/icons/topbar_medium.png');
                                background-position: 0 16px;
                                background-size: 30px;
                                width: 30px;
                                height: 16px;
                                @include inline-block(middle);
                            }
                        }

                        &.search {
                            color: $darker-gray;

                            .icon {
                                background: url('/assets/images/icons/search_medium.png');
                                background-size: 22px;
                                width: 22px;
                                height: 22px;
                                @include inline-block(middle);
                            }
                        }

                        &.main .icon {
                            background: url('/assets/images/icons/menu_medium.png');
                            background-size: 34px;
                            width: 34px;
                            height: 22px;
                            @include inline-block(middle);
                        }

                        .label {
                            margin-left: .5em;
                            display: inline;
                        }
                    }
                }
            }

            .small_navigation {
                @include inline-block(top);
                height: $height;
                line-height: $height;
            }
        }
    }
}
