.brandbox {
    min-height: 210px;

    // This part is so slider is hidden when not fully loaded yet
    > ul {
        height: 0;
        overflow: hidden;
        width: 100%;
    }

    .orbit-container {
        position: relative;

        &.hide-progressbar .orbit-timer,
        &.hide-progressbar .orbit-prev,
        &.hide-progressbar .orbit-next,
        &.hide-progressbar .orbit-bullets-container {
            display: none;
        }

        // Brandbox Content
        .orbit-slides-container li {
            .textual-content {
                padding: 20px;
                position: relative;
                height: 170px;
                background-color: $light-gray;

                h2 {
                    @include x-rem(font-size, 1.6);
                    @include x-rem(line-height, 1.6);
                    color: $primary-color;
                    margin: 0;
                }

                .text {
                    padding-bottom: 20px;

                    .title {
                        padding-bottom: 15px;
                    }
                }

                .body {
                    display: none;
                }

                .label a {
                    @include border-radius(3px);
                    @include inline-block;
                    @include box-shadow(0 1px 1px $black);
                    padding: 0 40px;
                    height: 45px;
                    line-height: 45px;
                    color: $white;
                    background-color: #ff1f24;
                    font-weight: bold;

                    &:hover,
                    &:focus {
                        @include background(linear-gradient(#ff393c, #d5050c));
                    }
                }
            }

            .backgroundimage-outer-wrapper {
                width: 100%;
                overflow: hidden;

                .backgroundimage-wrapper {
                    width: 100%;
                    position: relative;
                }

                .contentblockcontent.backgroundimage > img {
                    width: 166.5%;
                    max-width: 166.5%;
                }
            }

            .contentblock {
                position: relative;
                height: 100%;

                &.brandboxpillars {
                    .pillars {
                        width: 50%;
                        height: 90%;
                        text-align: right;
                        z-index: 2;
                        position: absolute;
                        bottom: 0;
                        right: 0;

                        .contentblockcontent {
                            @include inline-block(top);
                            height: auto;
                            text-align: right;

                            a {
                                display: block;
                                width: 100%;
                                height: 100%;
                            }

                            img {
                                max-height: 100%;
                                width: 95%;
                            }
                        }
                    }
                }

                .brandboxvideo {
                    .youtubeid {
                        position: absolute;
                        left: 50%;
                        top: 3%;
                        height: 94%;
                        width: 70%;
                        margin-left: -35%;
                        max-width: 600px;

                        > div {
                            position: relative;
                            width: 100%;
                            height: 100%;
                        }

                        iframe,
                        object,
                        embed {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }

        // Orbit timer
        .orbit-timer {
            top: auto;
            bottom: 170px;
            margin-top: -6px;
            right: 0;
            width: 100%;

            .orbit-progress {
                background-color: #f4f3f5;
                top: auto;
                bottom: 0;
                height: 6px;
                right: 0;
            }

            & > span {
                border: 0;
                display: none;
            }
        }

        // Orbit bullets (jumpers)
        .orbit-bullets-container {
            display: none;
        }

        // Orbit prev and next controls
        .orbit-prev,
        .orbit-next {
            height: 100%;
            width: 65px;
            top: 0;
            margin-top: 0;
            border: 0;
            background: none;

            &:hover,
            &:focus {
                background: none;
                border: 0;
            }

            & > span {
                background: url('/assets/images/icons/brandbox_prev_small.png') no-repeat;
                border: 0;
                height: 107px;
                width: 46px;
                top: 50%;
                display: block;
                margin-top: -((image-height(sprite-file($icons, brandbox_prev_small)) / 2) + 85);
                z-index: 3;
            }

            &.orbit-next > span {
                height: 107px;
                width: 46px;
                background: url('/assets/images/icons/brandbox_next_small.png') no-repeat;
                left: auto;
                right: 0;
                margin-left: 42px;
                margin-right: 0;
                z-index: 3;
            }
        }

        // Desktop style
        @include respond-min($medium-bp) {
            // Brandbox Content
            .orbit-slides-container li {
                .textual-content .body {
                    @include x-rem(line-height, 1.333);
                    display: block;
                }

                .textual-content .label a {
                    padding: 0 65px;
                }

                .backgroundimage-outer-wrapper .contentblockcontent.backgroundimage > img {
                    width: 100%;
                    max-width: none;
                    margin-left: 0;
                }

                .contentblock.brandboxvideo .youtubeid {
                    width: 40%;
                    margin-left: -20%;
                }
            }
            // Orbit prev and next controls
            .orbit-prev,
            .orbit-next {
                @include opacity(.5);
                width: 110px;

                span {
                    @include icon(brandbox_prev);
                    margin-top: -((image-height(sprite-file($icons, brandbox_prev)) / 2) + 85);
                }

                &.orbit-next span {
                    @include icon(brandbox_next);
                    margin-left: 64px;
                }
            }

            .contentblock.brandboxpillars .pillars .pillar-background {
                top: 5%;
                right: 3%;
                width: 41.5%;
            }

            .contentblock.brandboxpillars .pillars {
                top: 0;
                bottom: auto;

                .contentblockcontent {
                    width: 37%;
                    padding: 4.5% 0;

                    &.pillar1_image {
                        right: 5.4%;
                    }

                    &.pillar2_image {
                        right: 5.3%;
                    }

                    &.pillar3_image {
                        right: 5.4%;
                    }

                    &.pillar4_image {
                        right: 5.1%;
                    }
                }
            }
        }

        // Desktop style
        @include respond-min($large-bp) {
            // Brandbox Content
            .orbit-slides-container li {
                overflow: hidden;

                // General
                .vertical-aligner-wrapper {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                }

                .vertical-aligner {
                    display: table;
                    height: 100%;
                    width: 100%;

                    .vertical-aligner-inner {
                        display: table-cell;
                        vertical-align: middle;
                    }

                    .textual-content {
                        height: auto;
                        top: auto;
                        margin-top: -500px;
                        position: relative;
                    }

                    .pillar-background {
                        width: 40.5%;
                        right: 4.5%;
                        top: 3%;
                    }
                }

                .textual-content {
                    left: -50%;
                    top: 40px;
                    margin-left: -($row-width / 2);
                    width: 600px;
                    background: none;
                    padding: 0 0 0 25px;
                    position: absolute;
                    margin-top: -1000px;

                    h2 {
                        @include text-shadow(1px 1px 0 #a2a6ac);
                        @include x-rem(font-size, 2.2);
                        @include x-rem(line-height, 2.4);
                    }
                }

                .contentblockcontent.title.white h2 {
                    color: $white;
                }

                // Contentblock specific
                .contentblock.brandboximagetextlabel .textual-content {
                    background-color: $white;
                    background-color: rgba($white, .8);
                    min-height: 170px;
                    height: auto;
                    width: 510px;
                    padding: 0 20px;
                    margin-left: -(($row-width - 50) / 2);

                    h2 {
                        @include text-shadow(none);
                    }

                    .text {
                        height: auto;
                        width: 475px;
                        padding: 0 0 18px;

                        .title {
                            padding: 15px 0 17px;
                        }
                    }
                }

                .contentblock.brandboxvideo {
                    .textual-content {
                        width: 380px;
                        top: 0;
                    }

                    .youtubeid {
                        position: relative;
                        z-index: 999;
                        left: 150%;
                        top: auto;
                        min-height: 300px;
                        height: 75%;
                        width: 50%;
                        margin-top: 0;
                        margin-left: -(($row-width / 2) - 395);

                        > div {
                            min-height: 300px;
                            display: inline-block;
                        }
                    }
                }
            }

            // Animations
            .orbit-slides-container li.active {
                .textual-content,
                .vertical-aligner .textual-content {
                    margin-top: 0;
                }

                .textual-content {
                    @include transition-delay(.2s);
                    @include single-transition(left, .8s, ease-in-out);
                    left: 50%;
                    margin-top: 0;
                }

                .contentblock.brandboxvideo .youtubeid,
                .contentblock.brandboxpillars .pillars {
                    @include transition-delay(.01s);
                    @include single-transition(left, .8s, ease-in-out);
                    left: 50%;
                    top: 19px;
                }
            }

            .orbit-slides-container li.animation-finished {
                .vertical-aligner .textual-content {
                    margin-top: 0;
                }

                .textual-content {
                    margin-top: 0;
                }

                .ie8 & .vertical-aligner .textual-content,
                .ie8 & .textual-content {
                    margin-top: -1200px;
                }

                .textual-content,
                .contentblock.brandboxvideo .youtubeid,
                .contentblock.brandboxpillars .pillars {
                    @include single-transition(opacity, .2s, ease-in-out);
                    @include opacity(0);
                    left: 50%;
                }
            }

            .orbit-slides-container li.animation-finished.active {
                .textual-content,
                .contentblock.brandboxvideo .youtubeid,
                .contentblock.brandboxpillars .pillars {
                    @include single-transition(none);
                    @include opacity(1);
                    left: 50%;
                }

                .ie8 & {
                    .textual-content {
                        margin-top: 0;
                    }

                    .vertical-aligner .textual-content {
                        margin-top: 0;
                    }
                }
            }

            .orbit-prev,
            .orbit-next {
                &.orbit-prev span {
                    @include single-transition(left, .3s, ease-in-out);
                }

                &.orbit-next span {
                    @include single-transition(right, .3s, ease-in-out);
                }
            }

            .orbit-prev:hover,
            .orbit-next:hover {
                &.orbit-prev span {
                    left: 0;
                }

                &.orbit-next span {
                    right: 0;
                }
            }


            // Timer
            .orbit-timer {
                bottom: 0;
                margin-top: 0;
            }

            // Orbit prev and next controls
            .orbit-prev,
            .orbit-next {
                @include opacity(1);
                top: 0;
                height: 100%;
                padding-bottom: 0;
                margin-top: 0;
                vertical-align: middle;
                background: url('/assets/shared/assets/images/pixel.png') repeat;
                display: block;

                span {
                    display: block;
                    position: absolute;
                    left: -50px;
                    margin-top: -(image-height(sprite-file($icons, brandbox_prev)) / 2);
                }

                &.orbit-next span {
                    left: auto;
                    right: -50px;
                    margin-left: 0;
                }
            }

            // Orbit bullets (jumpers)
            .orbit-bullets-container {
                z-index: 5;
                top: auto;
                text-align: left;
                position: absolute;
                bottom: 0;
                left: 50%;
                margin-left: -($row-width / 2);
                width: $row-width;
                display: block;

                .orbit-bullets {
                    top: 0;
                    overflow: visible;
                    margin-bottom: 20px;
                    margin-left: 25px;
                    text-align: left;
                }

                li {
                    height: 32px;
                    width: 32px;
                    background: $primary-color;
                    border: solid 3px $white;
                    cursor: pointer;
                    position: relative;
                    z-index: 6;

                    &.active {
                        background: $secondary-color;
                    }
                }

                li:hover {
                    background: #cc040d;

                    .preview {
                        display: block;
                    }
                }

                .preview {
                    @include box-shadow(0 -1px 5px $black);
                    display: none;
                    position: absolute;
                    bottom: 40px;
                    left: -60px;
                    width: 141px;
                    min-height: 60px;
                    padding: 3px;
                    background-color: $white;
                    text-align: left;

                    .arrow {
                        border-top: 8px solid $white;
                        border-right: 8px solid transparent;
                        border-left: 8px solid transparent;
                        position: absolute;
                        bottom: -8px;
                        left: 50%;
                        margin-left: -8px;
                    }

                    span {
                        display: block;
                        color: $primary-color;
                        margin-bottom: 2px;
                    }
                }
            }
        }

        @include respond-min($xlarge-bp) {
            .orbit-slides-container li {
                .contentblock.brandboxpillars .textual-content {
                    height: 285px;
                }
            }
        }
    }
}

.contentblock.brandboxpillars {
    h2 {
        @include x-rem(font-size, 2.75);
        @include x-rem(line-height, 3.25);
    }

    .textual-content {
        padding-left: 25px;
    }

    .textual-content-inner {
        width: 410px;
        height: auto;
        margin-top: 0;
        padding: 10px 15px 20px;
        background: $white;
        background: rgba($white, .8);

        .title {
            padding-bottom: 0;

            h2 {
                @include x-rem(font-size, 2.2);
                text-shadow: none;
            }
        }

        p {
            @include x-rem(font-size, 1.1);
            @include x-rem(line-height, 1.3);
            margin-bottom: 0;

            @include respond-min(1280px) {
                @include x-rem(font-size, 1.2);
                @include x-rem(line-height, 1.6);
            }
        }
    }

    .pillars {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 50%;
        height: 100%;
        text-align: right;

        .pillar-background {
            position: absolute;
            height: 100%;
            width: 62.5%;
            right: 0;
            top: 3%;
            background: #5799a8;
            z-index: -2;
        }

        .contentblockcontent {
            margin-right: 0;
            position: relative;
            z-index: 2;
            display: inline-block;
            width: 55%;
            float: right;
            clear: both;
            padding: 6.5% 0;

            a {
                position: absolute;
                right: 0;
                width: 100%;
                height: 100%;
                bottom: 0;
            }

            img {
                display: none;
            }

            &.pillar1_image {
                right: 2.4%;
                top: 9.3%;
                -ms-transform: rotate(-1deg);
                transform: rotate(-1deg);

                &:hover {
                    -ms-transform: rotate(1deg);
                    transform: rotate(1deg);
                }
            }

            &.pillar2_image {
                right: 2.3%;
                top: 19.36%;
                -ms-transform: rotate(1.4deg);
                transform: rotate(1.4deg);

                &:hover {
                    -ms-transform: rotate(-1.4deg);
                    transform: rotate(-1.4deg);
                }
            }

            &.pillar3_image {
                right: 2.4%;
                top: 26.2%;
                -ms-transform: rotate(-2.2deg);
                transform: rotate(-2.2deg);

                &:hover {
                    -ms-transform: rotate(2.2deg);
                    transform: rotate(2.2deg);
                }
            }

            &.pillar4_image {
                right: 2.1%;
                top: 35.013%;
                -ms-transform: rotate(1.1deg);
                transform: rotate(1.1deg);

                &:hover {
                    -ms-transform: rotate(-1.1deg);
                    transform: rotate(-1.1deg);
                }
            }
        }
    }
}

