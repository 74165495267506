section.row.video_page .main-content {
    @include grid-column(12, $collapse: true);
    max-width: none;
}

#video-overview {
    @include grid-row();
    background: $white;
    border: 1px solid $border-color;
    border-top: 0;

    .video-items {
        width: 100%;
    }

    .video-item {
        @include grid-column(6, $collapse: true);
        position: relative;

        .description {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 20px;
            color: $white;
            background: $real-black;
            background: rgba($real-black, .5);
        }

        .link-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('/assets/images/play.png') no-repeat center center;
        }
    }

    .more-items-button {
        width: 100%;
        background: $medium-gray;
        padding: 20px;
        clear: both;

        a {
            margin-bottom: 0;
        }
    }
}


@include respond-min($medium-bp) {
    #video-overview {
        .video-item {
            @include grid-column(4, $collapse: true);
        }
    }
}
