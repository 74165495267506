section.home {
    .main-content {
        padding: 0 20px;
        margin-top: 40px;
        margin-bottom: 20px;

        @include respond-min($large-bp) {
            float: left;
            margin-top: 7rem;
        }
    }

    .has-sidebar {
        .main-content {
            @include respond-min($large-bp) {
                width: 75%;
            }
        }
    }

    .no-sidebar {
        .main-content {
            @include respond-min($large-bp) {
                width: 100%;
            }
        }
    }

    .article-content {
        border: 0;
        padding: 0;
    }

    h2 {
        @include x-rem(font-size, 1.867);
        @include x-rem(line-height, 2);
        color: $secondary-color;
        font-weight: normal;
    }
}

// Actuality overview on home page

.actualities-container {
    position: relative;
    padding: 40px 0;
    background: url('/assets/images/pattern_actualitiesbox.png') repeat;

    .row {
        padding: 0 20px;
    }

    h2.block-header {
        margin-bottom: 20px;
    }

    .ajax-loader {
        background-position: center 190px;
    }

    .actuality-box {
        $column-gutter: 40px;
        @include grid-column(12, $collapse: true);
        margin-bottom: 20px;

        .actuality-box-inner {
            border: 1px solid #d7d5d5;
            height: 224px;
            max-width: 486px;
        }

        .actuality-box-content {
            border-left: 30px solid;
            position: relative;
            width: 100%;
            height: 100%;

            .icon {
                @include icon(discount);
                position: absolute;
                top: 50%;
                left: -25px;
                margin-top: -9px;
            }

            .image {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: $white;
                overflow: hidden;

                img {
                    max-width: none;
                }

                .no-image {
                    @include opacity(.5);
                    width: 90%;
                    margin-left: 5%;
                }
            }

            .text {
                padding: 20px;

                h2 {
                    color: $primary-color;
                    font-size: 16px;
                    font-weight: bold;
                    margin: 0;
                }
            }

            .link-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;
            }
        }

        &.type-discount .actuality-box-content {
            border-left-color: #ffb21c;

            .icon {
                background: url('/assets/images/icons/discount.png') no-repeat;
                width: 20px;
                height: 20px;
            }

            .image {
                background: transparent;
                width: auto;
                z-index: 1;
            }

            .text {
                padding-left: 210px;
                width: 100%;
                height: 100%;
                background: $white;
            }

            .discount-actions-wrapper {
                border-top: 1px solid $medium-gray;
                position: absolute;
                bottom: 0;
                padding-left: 190px;
                width: 100%;
                left: 0;
            }

            .discount-actions {
                @include actions();
                .action {
                    width: 100% !important;
                }
            }
        }

        &.type-food .actuality-box-content {
            border-left-color: #9aba09;

            .icon {
                background: url('/assets/images/icons/healthy_food.png') no-repeat;
                height: 19px;
                width: 19px;
            }

            .text {
                background: #0c2b6f;
                background: rgba(#0c2b6f, .5);
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 10px 20px 20px;
                color: $white;
                font-size: 16px;

                h2 {
                    color: $white;
                    font-size: 20px;
                    margin-bottom: 2px;
                }
            }
        }

        &.type-video .actuality-box-content {
            border-left-color: #a65595;

            .icon {
                @include icon(video) {
                    color: $white;
                };
            }

            .text {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                background: $white;
                background: rgba($white, .75);
                padding: 10px 20px;

                h2 {
                    font-size: 18px;
                }
            }

            .link-overlay {
                background: url('/assets/images/play.png') no-repeat center center;
            }
        }

        &.type-discussion .actuality-box-content {
            border-left-color: #4fbdd5;

            .icon {
                @include icon(bubbles2) {
                    color: $white;
                };
            }

            .text {
                background: #fbfbfb;
                width: 100%;
                height: 100%;
            }

            .comment-count {
                background: $white;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 17px 20px;
                border-top: 1px solid #d7d5d5;

                a {
                    color: $medium-dark-gray;
                }

                .comment-count-icon {
                    @include inline-block;
                    margin-right: 10px;

                    @include icon(bubble) {
                        color: $primary-color;
                    };
                }
            }
        }
    }

    .filters {
        ul {
            margin-left: 0;
        }

        a {
            background: $white;
            padding: 10px 20px;
            text-decoration: underline;
        }

        li.active {
            a {
                padding-bottom: 2px;
            }
        }
    }
}

// Poll Block
#poll-block {
    padding-bottom: 20px;

    h2 {
        margin-bottom: 10px;
    }

    .poll-title {
        background: #5799a8;
        padding: 15px;

        h3 {
            color: $white;
            font-size: 16px;
            margin: 0;
        }
    }

    .poll-answers {
        border: 1px solid $border-color;
        padding: 20px;

        .submit_field input {
            @include facilicom-button();
            background-color: #e9e9e9;
            width: 100%;
        }

        .radio_field .option_field {
            margin-bottom: 20px;

            label {
                @include inline-block(middle);
                margin-left: 0;
                font-weight: bold;
            }

            input {
                display: none;
            }

            input + label {
                margin-right: 0;
            }

            input + label .bg {
                @include inline-block(middle);
                background: url('/assets/images/icons/radiobutton.png') no-repeat;
                background-size: 12px 12px;
                height: 12px;
                width: 12px;
                margin: -2px 10px 0 0;
                cursor: pointer;
            }

            input:checked + label .bg {
                background: url('/assets/images/icons/radiobutton_checked.png') no-repeat;
                background-size: 12px 12px;
                height: 12px;
                width: 12px;
            }

            input + label .name {
                @include inline-block(middle);
                width: 100%;
                box-sizing: border-box;
                padding-left: 20px;
                margin-top: -26px;
            }

            // Old IE
            @if $fix-mqs {
                input {
                    @include inline-block;
                }

                input + label .bg {
                    display: none;
                }
            }
        }
    }

    .view-poll-results {
        background-color: #f0f0f0;
        border: 1px solid $border-color;
        border-top: 0;
        padding: 20px;
        padding-left: 70px;
        position: relative;
        cursor: pointer;

        &:hover {
            background: linear-gradient(#fefefe, #eee);
        }

        .icon {
            background: url('/assets/images/poll-results.png') no-repeat;
            height: 32px;
            width: 32px;
            position: absolute;
            top: 15px;
            left: 20px;
        }

        strong {
            color: #002e6f;
            margin-bottom: 3px;
            display: block;
        }

        .view-poll-results-link {
            cursor: pointer;
        }
    }

    .poll-results {
        border: 1px solid $border-color;
        display: none;

        .poll-result {
            padding: 15px 10px 0 15px;
            border-top: 1px solid $border-color;

            &:first-child {
                border-top: 0;
            }

            .title {
                margin-bottom: 5px;
            }

            .result-bar {
                background: #f0f0f0;
                border: 1px solid $border-color;
                width: 100%;
                height: 21px;
                position: relative;

                .graphic-wrapper {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 21px;
                    width: 100%;
                }

                span {
                    @include inline-block;
                    @include background(linear-gradient(#afc73b, #9bbb0c));
                    height: 20px;
                    background-color: #afc73b;
                    border: 1px solid #9aba09;
                    margin-right: 9px;

                    &.empty {
                        border: 0;
                    }
                }
            }

            .result-amount {
                position: relative;
                top: -18px;
                left: 6px;
            }
        }
    }

    .back-to-form {
        background-color: #f0f0f0;
        border: 1px solid $border-color;
        border-top: 0;
        padding: 20px;
        display: none;
    }

    &.show-results {
        .poll-results,
        .back-to-form {
            display: block;
        }

        .poll-answers,
        .view-poll-results {
            display: none;
        }
    }
}

@media #{$medium-only} {
    .actualities-container .row {
        max-width: 744px;
    }
}

@include respond-min($medium-bp) {
    section.home {
        .filters {
            a {
                padding: 2px 20px;
                text-decoration: none;
            }

            .discount a {
                border-bottom: 4px solid #ffb21c;
            }

            .food a {
                border-bottom: 4px solid #9aba09;
            }

            .video a {
                border-bottom: 4px solid #a65595;
            }

            .discussion a {
                border-bottom: 4px solid #4fbdd5;
            }
        }
    }

    .actualities-container {
        .row {
            padding: 0;
        }

        .actuality-box {
            $column-gutter: 20px;
            @include grid-column(6);
        }
    }

    #poll-block {
        padding-left: 0;
        padding-right: 0;
    }
}

@include respond-min($large-bp) {
    section.home {
        .main-content {
            padding: 0;
        }

        .actualities {
            margin: 0 -10px;
        }

        .filters a {
            &:hover {
                padding-bottom: 2px;
            }
        }
    }
}
