.contentblocks {
    .contentblock.youtube {
        margin-bottom: 30px;

        .youtubeid {
            padding: 0;
            position: relative;
            padding-bottom: 56.25%;
            padding-top: 30px;
            height: 0;
            overflow: hidden;

            iframe,
            object,
            embed {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        h2 {
            margin-top: 0;
        }
    }
}