.requestedarticle {
    .news-date {
        width: 100%;
        background-color: $light-gray;
        height: 33px;
        line-height: 33px;
        padding: 0 20px;
        margin-top: -20px;
        margin-bottom: 20px;
    }
}

.articlelist {
    position: relative;

    &.animating {
        overflow: hidden;
    }

    .ajax-loader {
        background-position: center 130px;
    }

    .yearform_form {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 3;
    }

    .year-header,
    .month-header {
        @include x-rem(font-size, 1.3);
        color: $black;
        padding-left: 20px;
        position: relative;
    }

    .year-header {
        background-color: $medium-gray;
        height: 51px;
        line-height: 51px;
    }

    .month-header {
        background-color: $light-gray;
        height: 54px;
        line-height: 54px;
        margin-top: -1px;
        border-bottom: 1px solid $border-color;
    }

    .article_wrapper {
        padding: 1px;
        border: 1px solid $border-color;
        position: relative;
        border-top: 0;
        background: $white;

        .article {
            padding-right: 20px;
        }

        .date-and-photo {
            width: 85px;
            float: left;
            margin-right: 20px;

            .news-date {
                height: 33px;
                line-height: 33px;
                padding-left: 20px;
                background-color: $light-gray;
            }

            .full-date {
                display: none;
            }

            .photo {
                position: relative;
                width: 85px;
                height: 56px;
                display: table;

                .no-image {
                    vertical-align: middle;
                    display: table-cell;
                    text-align: center;

                    img {
                        @include opacity(.3);
                        width: 90%;
                    }
                }
            }
        }

        .description {
            height: 89px;

            h2 {
                @include x-rem(font-size, 1);
                margin-top: 0;
                padding-top: 10px;
                margin-bottom: 0;
                font-weight: normal;

                a {
                    text-decoration: none;
                }
            }

            p {
                display: none;
            }
        }
    }
}

// Tablet style
@include respond-min($medium-bp) {
    .articlelist {
        .article_wrapper {
            .date-and-photo {
                width: 140px;

                .short-date {
                    display: none;
                }

                .full-date {
                    display: block;
                }

                .photo {
                    width: 140px;
                    height: 93px;
                }
            }

            .description {
                height: 126px;

                p {
                    display: block;
                }

                h2 {
                    @include x-rem(font-size, 1.2);
                }
            }
        }
    }
}
