#discount-overview {
    .type-overview {
        position: relative;

        .active {
            .ajax-caller {
                display: none;
            }

            .back-to-overview {
                display: block;
            }
        }

        .discount-items {
            @include grid-row();
            @include x-rem(padding, 2 .66 0);
            border: 1px solid $border-color;
            background: $white;
        }

        .video .discount-item {
            min-height: 130px;
        }
    }

    .type-header {
        @include x-rem(padding-left, 1.33);
        @include x-rem(font-size, 1.3);
        background-color: $medium-gray;
        min-height: 54px;
        line-height: 54px;
        border: 1px solid $border-color;
        border-top: 0;
        border-bottom: 0;
        position: relative;

        .back-to-overview {
            display: none;
        }
    }

    .no-results {
        @include x-rem(padding, 1);
        border: 1px solid $border-color;
    }

    .discount-item {
        $column-gutter: 20px;
        @include grid-column(6);
        @include inline-block(top);
        @include x-rem(margin-bottom, 2);
        float: none;
        min-height: 290px;

        .photo {
            border: 1px solid $border-color;
            position: relative;

            a {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
            }
        }

        .name {
            margin-top: 10px;

            a {
                @include x-rem(font-size, 1);
                @include x-rem(line-height, 1.4);
                color: $primary-color;
                font-weight: bold;
                text-decoration: none;
            }
        }
    }

    .discount-actions {
        @include actions;

        .action {
            width: 100% !important;
        }
    }

    @include respond-min($medium-bp) {
        .type-header .button {
            position: absolute;
            top: 8px;
            right: 20px;
        }

        .discount-item {
            @include grid-column(3);
            @include inline-block(top);
            float: none;
        }
    }
}

@include respond-min($large-bp) {
    section.discount_page .main-content {
        @include grid-column(12, $collapse: true);
    }

    #discount-overview {
        .discount-item {
            @include grid-column(3);
            @include inline-block(top);
            float: none;
        }
    }
}
