&.accountpage {
    @import 'popout';

    height: 100vh;
    background-color: #e0e0e0;

    .search {
        opacity: 0;
    }

}