@import 'compass/utilities/sprites';
@import 'compass/css3';

@import 'compass/css3/background-size';
@import 'compass/css3/images';
@import 'compass/css3/box-shadow';
@import 'compass/css3/inline-block';
@import 'compass/css3/border-radius';
@import 'compass/css3/flexbox';
@import 'compass/reset/utilities';


@mixin facilicom-button {
    @include x-rem(padding, .75 1);
    @include border-radius(3px);

    display: block;
    background: $white;
    color: $heavy-dark-gray;
    text-align: center;
    text-decoration: none !important;
    border: 1px solid $medium-gray !important;
    border-top-color: $light-gray !important;
    border-bottom-color: $dark-gray !important;

    &:hover,
    &:focus {
        @include background(linear-gradient(#fefefe, $light-gray));
    }
}

// a small mixin for easy use of rem with px as fallback
// usage: @include x-rem(font-size, 14px)
// usage: @include x-rem(marign, 0 12px 2 1.2)
// usage: @include x-rem(padding, 1.5 24px)

// thanks to Eric Meyer for https://github.com/ericam/susy
// and Hans Christian Reinl for http://drublic.de/blog/rem-fallback-sass-less/

@mixin x-rem($property, $values) {
    // Create a couple of empty lists as output buffers.
    $px-values: ();
    $rem-values: ();

    // Loop through the $values list
    @each $value in $values {
        // For each property value, if it's in rem or px, derive both rem and
        // px values for it and add those to the end of the appropriate buffer.
        // Ensure all pixel values are rounded to the nearest pixel.
        @if $value == 0 or $value == 0px {
            // 0 -- use it without a unit
            $px-values: join($px-values, 0);
            $rem-values: join($rem-values, 0);
        } @else if type-of($value) == number and not unitless($value) and (unit($value) == px) {
            // px value given - calculate rem value from base-font-size
            $new-rem-value: $value / $base-font-size;
            $px-values: join($px-values, round($value));
            $rem-values: join($rem-values, #{$new-rem-value}rem);
        } @else {
            // unitless value - use those directly as rem and calculate the px-fallback
            $px-values: join($px-values, round($value * $base-font-size));
            $rem-values: join($rem-values, #{$value}rem);
        }
    }

    // output the converted rules
    #{$property}: $px-values;
    #{$property}: $rem-values;
}

// media query mixins
$fix-mqs: false !default;
$old-ie: false !default;

@mixin respond-min($width) {
    // If we're outputting for a fixed media query set...
    @if $fix-mqs {
        // ...and if we should apply these rules...
        @if $fix-mqs >= $width {
            // ...output the content the user gave us.
            @content;
        }
    } @else {
        // Otherwise, output it using a regular media query
        @media screen and (min-width: $width) {
            @content;
        }
    }
}

@mixin old-ie {
    // Only use this content if we're dealing with old IE
    @if $old-ie {
        @content;
    }
}

.content .show-for-small-only {
    @include respond-min($medium-bp) {
        display: none !important;
    }
}

.content .show-for-medium-up {
    @include respond-min($medium-bp) {
        display: inherit !important;
    }
}

@include old-ie {
    .content {
        min-width: $large-bp;
    }
}


@mixin actions {
    border: 1px solid $border-color;
    border-top: 0;
    background-color: $border-color;

    .action {
        @include inline-block(top);
        width: 50%;
        height: 51px;
        border: 1px solid $white;
        position: relative;
        background-color: $light-gray;

        &:first-child {
            margin-right: 1px;
            width: 49%;
            width: calc(50% - 1px);
        }

        &:hover {
            background-color: $medium-gray;
        }

        a {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            z-index: 2;
        }

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
        }
    }

    .download {
        @include icon(download);
        margin-top: -(image-height(sprite-file($icons, download))/2);
        margin-left: -(image-width(sprite-file($icons, download))/2);
    }

    .link {
        @include icon(magnifyglass_22x22);
        margin-top: -(image-height(sprite-file($icons, magnifyglass_22x22))/2);
        margin-left: -(image-width(sprite-file($icons, magnifyglass_22x22))/2);
    }
}
