// Quick fixes for aeroplane menu height
.aeroplane_bar ul.aeroplane_menu ul.aeroplane_submenu li,
.aeroplane_contextbutton ul.aeroplane_submenu li {
    height: 1.3em !important;
}

.aeroplane_gui .dijitContentPane h1,
.aeroplane_gui .dijitTabPane h1.dijitContentPane {
    height: 37px !important;
}

.aeroplane_gui select {
    width: auto;
}

.aeroplane_databaseeditor_table form div.pos_formelement {
    height: 25px !important;
}

.aeroplane_wjgroup_contentcollector div.aeroplane_wjtext_index_name {
    display: none;
}
