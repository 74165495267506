// Footer styling
footer {
    h2 {
        @include x-rem(font-size, 1.2);
        @include x-rem(margin-bottom, 1);
        @include x-rem(margin-top, .5);
        color: $primary-color;
    }

    // Footer navigation
    &.navigation {
        background: $light-gray;
        padding-bottom: 8px;

        .submenu {
            width: 100%;
            max-width: 992px;
            margin: 0 auto;
            padding: 1em 0;

            @include respond-min($large-bp) {
                .services {
                    width: 75%;
                    @include inline-block(top);
                }

                .social-media {
                    width: 25%;
                    @include inline-block(top);
                }
            }

            .main_menu {
                list-style: none;
                margin-left: 0;
                margin-bottom: 0;

                li:first-child {
                    display: none;
                }

                li {
                    @include inline-block(top);

                    margin: 0 0 1em;
                    width: 100%;

                    @include respond-min($medium-bp) {
                        width: 49%;
                    }

                    a {
                        font-weight: bold;
                        color: $medium-dark-gray;
                    }

                    ul {
                        margin-left: 0;
                        list-style: none;

                        li {
                            @include inline-block(top);

                            width: 100%;

                            a {
                                font-weight: normal;
                                color: $medium-dark-gray;
                                cursor: pointer;
                            }

                            &.first,
                            &.pair {
                                display: inline-block;
                            }
                        }

                        &.submenu {
                            padding: 0;
                            li a {
                                cursor: pointer;
                            }
                        }
                    }

                    &.has_submenu {
                        a {
                            cursor: text;
                        }
                    }
                }
            }

            .social-media,
            .login,
            .general {
                margin-bottom: 1em;
            }
        }
    }

    .social-media {

        p {
            margin-top: 0;
        }

        ul {
            margin: 0;

            li {
                display: inline-block;
                overflow: hidden;
                width: 27px;
                height: 27px;
            }
        }

        .facebook {
            @include inline-image(facebook) {
                width: 27px;
                height: 27px;
            };

            &:hover {
                color: $facebook-blue;
                text-decoration: none;
            }
        }

        .linkedin {
            @include inline-image(linkedin) {
                width: 27px;
                height: 27px;
            };

            &:hover {
                color: $linkedin-blue;
                text-decoration: none;
            }
        }

        .twitter {
            @include inline-image(twitter) {
                width: 27px;
                height: 27px;
            };

            &:hover {
                color: $white;
                text-decoration: none;
            }
        }

        .youtube {
            @include inline-image(youtube) {
                width: 27px;
                height: 27px;
            };

            &:hover {
                color: $white;
                text-decoration: none;
            }
        }

        .rss {
            @include inline-image(rss) {
                width: 27px;
                height: 27px;
            };

            &:hover {
                color: $rss-orange;
                text-decoration: none;
            }
        }

        .icon {
            display: inline-block;
            width: 100%;
            height: 100%;
            text-align: center;
        }
    }

    // Red footer bar
    &.footer {
        background: $secondary-color;

        div {
            padding-left: 0;
            max-width: 992px;
            margin: 0 auto;
            display: table;
            width: 100%;
            padding: 1em 0;

            section {
                color: $white;
                display: table-cell;
            }

            .name {
                width: 75%;
            }

            .payoff {
                width: 25%;
                margin-top: 14px;
                text-align: right;

                .payoff-head {
                    padding-top: 0;
                    font-size: 18px;
                    padding-bottom: 0;
                }

                .payoff-sub {
                    font-size: 14px;
                    font-style: italic;
                    padding-top: 4px;
                }
            }
        }
    }
}
