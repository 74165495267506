// Main content and sidebar layout
.emptycontentblockcontent {
    display: none;
}

.active-placeholder {
    color: $medium-gray;
}

.ajax-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url('/assets/shared/assets/images/ajax-loader.gif') no-repeat center center;
    background: url('/assets/shared/assets/images/ajax-loader.gif') no-repeat center center rgba($white,0.5);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#7Fffffff', endColorstr='#7Fffffff');
    z-index: 999999;
}
