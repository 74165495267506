// Logo and search
$background: #f3f3f3;

.header {
    z-index: 101;
    position: relative;
    background-color: $background;
    border-bottom: 1px solid $border-color;

    .logo-duurzaaminzetbaarheid {
        position: absolute;
        top: 0;
        left: 0;

        img {
            height: 63px;
            position: relative;
            z-index: 99999999;
        }

        .normal {
            display: none;
        }
    }

    .logo-facilicom {
        float: left;
        margin-left: 15px;
        padding-top: 20px;

        img {
            width: 160px;
        }
    }
}


// Tablet and up
@include respond-min($header-bp) {
    .header {
        .logo-duurzaaminzetbaarheid img {
            height: 93px;
        }

        .logo-facilicom {
            padding-top: 28px;

            img {
                width: 270px;
            }
        }
    }
}

// Desktop style
@include respond-min($large-bp) {
    $height: 90px;

    .header {
        border-bottom: 0 solid $border-color;
        $column-gutter: (1.875 * $base-font-size);

        .row {
            position: relative;

            .small_navigation {
                display: none;
            }
        }

        .logo-duurzaaminzetbaarheid {
            left: -28px;

            img {
                height: auto;

                &.small {
                    display: none;
                }

                &.normal {
                    display: block;
                }
            }
        }

        .logo-facilicom {
            margin-left: 16px;
            margin-right: 166px;
            padding-top: 27px;
        }
    }
}
