// This is the default html and body font-size for the base rem value.
$rem-base: 16px !default;
 
$modules: () !default; 
@mixin exports($name) {
   @if(not index($modules, $name)) {
    $modules: append($modules, $name) !global;
    @content;
  }
}

//
// @functions
//

@function lower-bound($range){
  @if length($range) <= 0 {
    @return 0;
  }
  @return nth($range,1);
}

@function upper-bound($range) {
  @if length($range) < 2 {
    @return 999999999999;
  }
  @return nth($range, 2);
}


// It strips the unit of measure and returns it
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

// New Syntax, allows to optionally calculate on a different base value to counter compounding effect of rem's.
// Call with 1, 2, 3 or 4 parameters, 'px' is not required but supported
// rem-calc(10 20 30px 40);
// Space delimited, if you want to delimit using comma's, wrap it in another pair of brackets
// rem-calc((10, 20, 30, 40px));
// Optionally call with a different base (eg: 8px) to calculate rem.
// rem-calc(16px 32px 48px, 8px);
// If you require to comma separate your list
// rem-calc((16px, 32px, 48), 8px);

@function convert-to-rem($value, $base-value: $rem-base)  {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;
  @if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
  @return $value;
}

@function rem-calc($values, $base-value: $rem-base) {
  $max: length($values);

  @if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }

  $remValues: ();
  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }
  @return $remValues;
}

// Deprecated: We'll drop support for this in 5.1.0, use rem-calc()
@function emCalc($values){
  @return rem-calc($values);
}

// Deprecated: We'll drop support for this in 5.1.0, use rem-calc()
@function em-calc($values){
  @return rem-calc($values);
}

// Maybe you want to create rems with pixels
// $rem-base: 0.625 !default; //Set the value corresponding to body font size. In this case, you should set as: body {font-size: 62.5%;}
// @function remCalc($pxWidth) {
//   @return $pxWidth / $rem-base * 1rem;
// }
