// Styles for the contact page, contact form and question form
.contact_page {
    h1 {
        width: auto;
        padding-right: 0;
        margin-right: 0;
        background: $white;
        background: rgba($white, .75);
        padding: 15px 20px;
    }

    h2 {
        @include x-rem(margin-top, 1);
        @include x-rem(margin-bottom, 1.3);
    }
}

.question_form {
    h2 {
        @include x-rem(margin-bottom, 1.3);
    }
}

ul.contact_information_per_location {
    @include reset-list-style;
    margin: 0;
}

// Contact information panel
.contactinformation {
    @include x-rem(line-height, 1.666);
    border: 1px solid $border-color;
    background: $white;

    h2,
    .contact_field {
        padding: 0 20px;
    }

    .contact_field {
        @include grid-row(nested);
        height: 23px;
        clear: both;

        label {
            @include grid-column(6, $collapse: true);
            @include x-rem(font-size, 1);
            margin-bottom: 0;
        }

        .value {
            @include grid-column(6, $collapse: true);
            overflow: visible;
        }

        @include respond-min($medium-bp) {
            label {
                @include grid-column(5, $collapse: true);
            }

            .value {
                @include grid-column(7, $collapse: true);
                overflow: visible;
            }
        }

        @include respond-min($large-bp) {
            label {
                @include grid-column(4, $collapse: true);
            }

            .value {
                @include grid-column(8, $collapse: true);
            }
        }

        &.field_mailing_address,
        &.field_email_address {
            @include x-rem(margin-bottom, .8333);
        }
    }

    .map {
        height: 13.333em;
        border-top: 1px solid $border-color;
    }

    .info_window {
        display: none;
    }

    .text_balloon {
        @include x-rem(padding, 1);
        @include single-box-shadow(0, 0, 1px, 1px, rgba(0, 0, 0, .15));
        position: relative;
        border: 2px solid $white;
        background: $light-gray;

        &::before,
        &::after {
            position: absolute;
            left: -16px;
            top: 30px;
            content: ' ';
            border: 8px solid;
            border-top-width: 5px;
            border-bottom-width: 5px;
            border-color: transparent $white transparent transparent;
        }

        &::after {
            left: -13px;
            border-color: transparent $light-gray transparent transparent;
        }
    }

    .button {
        width: auto;
        display: block;
        margin-bottom: 0;
        white-space: nowrap;
    }

    &.sub {
        @include transition-property(all);
        @include transition-duration(.5s);
        @include transition-timing-function(cubic-bezier(0, 1, .5, 1));
        padding-bottom: 0;
        overflow-y: hidden;
        max-height: 55px;

        h2.toggle {
            margin-top: 0;
            margin-bottom: 0;
            padding-right: 0;
            vertical-align: middle;
            line-height: 53px;
            cursor: pointer;
        }

        .toggle_detail {
            display: block;
            float: right;
            width: 53px;
            line-height: 51px;
            border: 1px solid $white;
            background: $light-gray;
            color: $black;
            text-align: center;

            .plus {
                @include inline-block;
                @include icon(plus);
            }

            .minus {
                @include icon(minus);
                display: none;
            }
        }

        &.open {
            max-height: 500px; // estimation

            .toggle_detail {
                .plus {
                    display: none;
                }

                .minus {
                    @include inline-block;
                }
            }

            .contact_content {
                display: block;
            }
        }
    }
}

.contact_form,
.question_form {
    @include grid-row();
    background: $light-gray;
    padding: 20px 20px 0 20px;;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;

    h2 {
        @include grid-column(12, $collapse: true);
    }

    .longtext_field {
        @include grid-column(12, $collapse: true);
        float: none;
        & > textarea {
            @include x-rem(height, 9.5);
        }
    }

    .formfield.text_field input {
        width: 100%;
    }

    .contact_information_fields {
        @include grid-column(12, $collapse: true);

        .divisionfield {
            .button.dropdown {
                width: 222px;
            }

            .f-dropdown {
                max-width: 222px;
            }
        }
    }

    .submit {
        @include grid-column(12, $collapse: true);
        @include facilicom-button;
    }

    // Form result text
    .article-content > .contentblocks {
        @include x-rem(margin-top, 1);
    }
}

.contact_form_container {
    .article-content {
        background-color: $white;
        padding: 20px;
        border: 1px solid $border-color;
    }
}

.question_form {
    .formfield.subjectfield {
        @include grid-column(12, $collapse: true);

    }

    .contact_information_fields {
        .formfield:last-child input {
            margin-bottom: 1em;
        }
    }
}

@media #{$small-only} {
    .contact_data,
    .contact_form_container {
        margin-bottom: 20px;
    }

    .sub {
        margin-top: -1px;
    }
}

@include respond-min($medium-bp) {
    .contact_data,
    .contact_form_container,
    .left_contact,
    .right_contact {
        @include grid-column(6, $collapse: true);
        width: 49%;
    }

    .contact_data,
    ul.left_contact {
        margin-left: 0;
        margin-right: 1%;
    }
    .contact_form_container,
    ul.right_contact {
        margin-left: 1%;
        margin-right: 0;
    }

    .contact_form {
        border: 1px solid $border-color;

        .contact_information_fields {
            @include grid-column(12, $pull: 6, $collapse: true);
            position: static;
        }

        .submit {
            @include grid-column(12, $collapse: true);
        }
    }

    .question_form {
        border-top-width: 0;
    }

    .top_row {
        @include clearfix;
        margin-bottom: 20px;
    }

    .sub {
        margin-bottom: 20px;
    }
}

// Make main content full width
@include respond-min($large-bp) {
    .contact_page {
        .main-content {
            @include grid-column(12, $collapse: true);
        }

        .contactinformation.main .textual-information {
            min-height: 182px;
        }
    }

    .contact_form {
        .contact_information_fields {
            @include grid-column(12, $pull: 12, $collapse: true);
            position: static;
        }

        .submit {
            position: static;
            width: 100%;
        }
    }

    .question_form {
        .contact_information_fields {
            @include grid-column(4, $pull: 8, $collapse: true);
        }

        .required_fields_text {
            @include grid-column(8, $push: 4, $collapse: true);
            @include x-rem(padding-left, 1.333);
        }

        .submit {
            @include grid-column(4, $collapse: true);
        }
    }
}
