// Sidebar specific styling

.sidebar {
    @include x-rem(margin-bottom, 4);

    h2 {
        @include x-rem(font-size, 1.333);
        @include x-rem(line-height, 1.6);
        @include x-rem(margin-bottom, 1.33);
        margin-top: 0;
    }

    p {
        @include x-rem(padding-bottom, 1);
        margin-bottom: 0;
    }

    .related-articles {
        max-width: 338px;
        overflow: hidden;

        .related-article {
            background-color: $light-gray;
            margin-bottom: 1px;
            min-height: 65px;
            position: relative;
            display: table;
            width: 100%;

            &:hover {
                background-color: $medium-gray;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;

                &.no-image {
                    height: 65px;
                    border: 3px solid $white;
                }
            }

            a {
                text-decoration: none;
                padding: 12px;
                padding-left: 77px;
                display: table-cell;
                vertical-align: middle;
                width: 100%;
                position: relative;
                word-wrap: break-word;
                overflow-wrap: break-word;

                @include respond-min($medium-bp) {
                    max-width: 247px;
                }
            }
        }
    }
}
