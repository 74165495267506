form {
    margin-bottom: 0;
}

@media #{$small-only} {
    input[type='text'],
    textarea {
        font-size: 16px;
    } // fix for iOS zooming
}

.formfield.text_field input,
.formfield.text_field textarea,
.formfield.password_field input,
{
    width: 300px;
    max-width: 100%;
    border-color: #d5d4d4;
}

.formfield.missing input,
.formfield.missing textarea,
.formfield.select_field.missing .button.dropdown {
    border-color: $secondary-color;
}

.formfield.boolean_field label {
    @include x-rem(margin-left, .5);
    @include x-rem(margin-right, 1);
}

.button,
.button[disabled] {
    @include x-rem(font-size, 1);
    @include border-radius(3px);
    background-color: $white;
    padding: 10px 30px;
    color: $heavy-dark-gray;
    margin-bottom: 10px;
    text-decoration: none !important;
    border: 1px solid $medium-gray;
    border-top-color: $light-gray;
    border-bottom-color: $dark-gray;

    &:hover,
    &:focus {
        @include background(linear-gradient(#fefefe, $light-gray));
        color: $light-gray;
        text-decoration: none;
    }
}

.button[disabled] {
    background: #f3f3f3;
    color: #c0c0c0;

    &:hover,
    &:focus {
        background: #f3f3f3;
        color: #c0c0c0;
    }
}

.formfield input,
.formfield textarea {
    @include border-radius(3px);
    margin-bottom: 10px;
}

// Styled Select fields that are actually <ul>'s
.formfield.select_field {
    .button.dropdown {
        @include box-shadow(none);
        background: $white;
        border: 1px solid #d5d4d4;
        color: $medium-dark-gray;
        padding: 10px 50px 10px 15px;
        width: 190px;
        text-align: left;

        .right {
            position: absolute;
            right: 0;
            top: 0;
            width: 37px;
            height: 37px;
            border-left: 1px solid #d5d4d4;
        }

        .arrow {
            @include icon(arrow_dropdown_gray);
            position: absolute;
            right: 0;
            top: 0;
            margin-top: 16px;
            margin-right: 14px;
        }
    }

    .f-dropdown {
        margin: 0;
        margin-top: -1px;
        border: 0;

        &::after {
            border: 0;
        }

        li {
            position: relative;
            list-style-type: none;
            border: 1px solid #d5d4d4;
            border-top: 0;
            padding: 10px 15px;
            width: auto;
            background-color: $white;

            &:first-child {
                border-top: 1px solid #d5d4d4;
            }

            &:hover {
                background-color: $light-gray;
            }
        }
    }
}

.js-float-label-wrapper {
    position: relative;

    label {
        @include opacity(0);
        @include x-rem(font-size, .8);
        position: absolute;
        top: 1em;
        left: 1em;
        color: #999;
        z-index: 4;
    }

    input {
        padding: 8px 10px;
    }

    textarea {
        padding: 10px;
    }

    input,
    textarea,
    label {
        -webkit-transition: .2s ease-in-out;
        -moz-transition: .2s ease-in-out;
        -o-transition: .2s ease-in-out;
        transition: .2s ease-in-out;
    }

    &.focused label,
    &.populated label {
        @include opacity(1);
    }

    &.focused label {
        color: $primary-color;
    }

    &.focused input,
    &.populated input,
    &.populated textarea {
        @include x-rem(padding-top, 2.5);
    }

    &.focused.longtext_field {
        label {
            @include opacity(0);
        }

        textarea {
            padding-top: 10px;
        }
    }

    &.focused,
    &.populated {
        & > input {
            height: 60px;
        }
    }
}
