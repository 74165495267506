section.home .homebannerscontentblocks {
    @include grid-row();
    padding: 0;
    max-width: none;

    .title.text {
        background: $white;
    }

    h2 {
        @include x-rem(font-size, 1.867);
        @include x-rem(line-height, 2);
        @include x-rem(margin-bottom, 1.33);
        margin-top: 0;
    }

    .contentblock {
        $column-gutter: 0;
        @include grid-column(12);
        max-width: none;
        margin-bottom: 30px;

        p {
            @include x-rem(padding-bottom, 1);
            margin-bottom: 0;
        }

        &.news {
            .articlelist {
                border-top: 1px solid $medium-gray;
            }

            .news-block-footer {
                border: 1px solid $medium-gray;
                border-top: 0;
                height: 39px;
                color: $primary-color;
                position: relative;

                .to-overview {
                    position: absolute;
                    right: 0;
                    top: 0;
                    background-color: #e3e3e5;
                    display: block;
                    padding: 12px 20px;
                    text-decoration: none;
                }
            }
        }

        &.bannerimagetextlabel {
            .photo,
            .textual-content {
                background: $light-gray;
                color: $heavy-dark-gray;
                float: left;
                width: 50%;
                height: 200px;
                overflow: hidden;
            }

            .title {
                overflow: visible;
            }

            .text.richtext {
                padding: 20px 20px 0;

                p {
                    margin-top: 0;
                }
            }
            // Call to action
            .label {
                @include x-rem(padding, 1 1.3 1);

                & > a {
                    @include facilicom-button;
                }
            }

            .textual-content {
                background: $light-gray;
            }

            .for-desktop {
                display: none;
            }
        }
    }
}

@media #{$large-up} {
    section.home .homebannerscontentblocks {
        padding: 0;

        .contentblock {
            $column-gutter: 0;
            @include grid-column(3);
            @include inline-block(top);
            max-width: 338px;
            float: none;

            &.news {
                width: 100%;
                max-width: none;
            }

            &.bannerimagetextlabel {
                display: none;
            }
        }
    }
}
