// Main navigation menu
$border-color: $medium-gray;
$background: $light-gray;
$hover-background-color: $background;
$navigation-use-inline-images: true !default;

.main_navigation {
    z-index: 5;
    background: $white;
    border-top: 1px solid $white;
    position: relative;

    &.open .main_menu-wrapper > .main_menu {
        display: block;
    }

    ul, li {
        list-style-type: none;
    }

    .main_menu {
        &::before {
            // Fix clearfix to remove empty pixel line
            content: none !important;
        }

        & li {
            border-bottom: 1px solid $border-color;
        }

        // Level 1
        & > li {
            &.has_submenu {
                .arrow {
                    @include inline-block(middle);
                    @include x-rem(margin-left, .5);

                    @if ($navigation-use-inline-images) {
                        @include inline-image(arrow_menu_blue) {
                            width: 8px;
                            height: 4px;
                        };
                    }
                }

                a {
                    position: relative;
                }
            }

            & a {
                display: block;
                padding: .9em 1em;
                height: auto;
                color: $primary-color;
                vertical-align: middle;
            }

            // Submenu
            ul.submenu {
                position: relative;

                li.pair {
                    background: $white;
                    border-bottom: 0 solid transparent;

                    .stl_active > a,
                    .has_submenu .stl_active a {
                        color: $primary-color;
                    }

                    & > ul > li {
                        // Level 2
                        & a {
                            background: $light-gray;
                        }
                        // Level 3
                        & a {
                            margin-left: .4em;
                            padding-left: 1em;
                        }

                        &.has_submenu {
                            border-bottom: 0 solid transparent;
                        }

                        &.has_submenu >  a {
                            border-bottom: 1px solid $border-color;
                            font-weight: bold;
                            margin-left: 0;
                            color: $black;

                            &:hover {
                                text-decoration: none !important;
                                cursor: default;
                            }
                        }
                    }
                }
            }
        }
        // Home item icon
        .home {
            @include inline-block(middle);
            @if ($navigation-use-inline-images) {
                @include inline-image('home') {
                    height: 15px;
                    width: 16px;
                };
            }
        }
    }
}

.main_navigation.hover > .main_menu {
    display: block;
}

// Desktop style
@include respond-min($large-bp) {
    .main_navigation {
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;

        .main_menu-wrapper {
            @include grid-row;
            width: 100%;
            margin: 0 auto !important; // Fix for center alignment
        }

        .main_menu {
            border-bottom: 0 solid transparent;
            display: table !important;
            text-align: center;
            width: 100%;

            li {
                padding: 0 20px;
                text-align: left;
            }

            li,
            a {
                border-bottom: 0 solid $border-color;
            }

            &::after {
                content: none !important;
            }

            // Level 1
            & > li {
                display: table-cell !important;
                border-right: 1px solid $border-color;

                &.hover {
                    &.has_submenu {
                        @include single-box-shadow(0, 1px, 0, 0, $hover-background-color);
                    }
                }

                & a {
                    display: inline;
                    height: auto;
                    line-height: 1em;
                    padding: 0;
                    border-bottom: 0 solid transparent !important;

                }

                & > a {
                    display: block;
                    white-space: nowrap;
                    height: 53px;
                    line-height: 53px;
                    background: inherit;
                }

                // Level 2 pairs
                ul.submenu {
                    @include grid-row;
                    @include x-rem(padding, 1 0 2);
                    @include box-shadow(
                        $background -980px 0 0,
                        $background 980px 0 0,
                        $white -980px 5px 0,
                        $white 0 5px 0,
                        $white 980px 5px 0
                    );
                    z-index: 100;
                    position: absolute !important;
                    // For menus without submenus, align with menu item
                    // Now handled in javascript
                    left: 0;
                    margin-top: 1px;
                    background: $background;
                    overflow-x: hidden;
                    text-align: left;

                    a {
                        margin: 0 !important;
                        color: #666;

                        &:hover {
                            color: $primary-color;
                            text-decoration: underline !important;
                            cursor: pointer;
                        }
                    }

                    li.pair {
                        // Include only with 'many items'
                        // Now handled in javascript
                        @include grid-column(3, $collapse:true);
                        background-color: inherit !important;

                        // Level 2 menu items
                        & > ul {
                            @include x-rem(padding, 1 0 0);
                            & > li {
                                white-space: nowrap;

                                & > a {
                                    @include x-rem(line-height, 1.2);
                                    display: block;
                                    text-decoration: none;
                                    padding-left: 0;
                                }
                                // Level 3
                                & > ul {
                                    margin-left: 0;

                                    & > li > a {
                                        @include x-rem(line-height, 1.2);
                                        display: block;
                                        margin: 15px 0 !important;

                                        &:hover {
                                            color: $primary-color;
                                            text-decoration: underline;
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
