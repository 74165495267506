#shirtofferform-container {
    form {
        background: $medium-light-grey;
        padding: 0 20px;

        .employee_numberfield input {
            width: 210px;
        }

        .postalcodefield input {
            width: 100px;
        }

        #employee-details {
            margin: 0 -20px;

            .submit_field {
                margin-top: 10px;
            }
        }

        &.step1 {
            padding-top: 20px;
        }

        .radio_field {
            margin-bottom: 3px;

            > label {
                @include inline-block;
                border-right: 1px solid $medium-light-grey;
                width: 110px;
                height: 31px;
                line-height: 31px;
                margin-bottom: 0;
                font-size: 15px;
            }

            input {
                display: none;
            }

            .option_field {
                @include inline-block;
            }

            label {
                margin-left: 0;
                margin-right: 0;
            }

            input + label .bg {
                @include inline-block;
                cursor: pointer;
                background: $white;
                padding: 8px 0;
                width: 67px;
                text-align: center;
                border: 1px solid $medium-light-grey;
                border-left: 0;
            }

            input:checked + label .bg {
                background: $primary-color;
                color: $white;
            }
             // Old IE
            @if $fix-mqs {
                > label {
                    border-right: 0;
                }

                input {
                    @include inline-block;
                }

                input + label .bg {
                    border: 0;
                    width: auto;
                    padding-left: 5px;
                    margin-right: 20px;
                    text-align: right;
                }

                input + label .bg,
                input:checked + label .bg {
                    background: transparent;
                }
            }
        }

        &.step1 #employee-details {
            &, label {
                color: #b8b8b8;
            }

            input + label .bg,
            input:checked + label .bg {
                color: #b8b8b8;
                background-color: #f3f3f3;
                cursor: auto;
            }
             // Old IE
            @if $fix-mqs {
                input + label .bg,
                input:checked + label .bg {
                    background: transparent;
                }
            }
        }
    }

    .searched-employee-number {
        background: $medium-light-grey;
        padding: 30px 20px;
        font-weight: bold;
        color: $primary-color;
    }

    #employee-details {
        padding: 20px;
        background: #eee;

        .field {
            margin-bottom: 15px;

            &.address {
                min-height: 30px;
            }
        }

        .label {
            width: 70px;
        }

        .label,
        .value {
            @include inline-block(top);
        }
    }
    // Ajax call
    .article-content {
        border: 0;
    }
}

@media #{$small-only} {
    #shirtofferform-container {
        .formfield.select_field .button.dropdown  {
            width: 180px;
        }

        .f-dropdown {
            max-width: 180px !important;
            margin-left: 2.5%;
        }
    }
}

@include respond-min($medium-bp) {
    #shirtofferform-container form {
        .employee_numberfield,
        .check_employeefield,
        .postalcodefield {
            @include inline-block;
        }

        .employee_numberfield,
        .postalcodefield,   {
            margin-right: 16px;
        }
    }
}
