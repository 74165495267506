#idc-container #IDNewThreadSubmitLI {
    float: none !important;
}

div#idc-container-parent {
    margin: 0px !important;
    padding: 20px !important;
    border-top: 1px solid $border-color;

    #idc-container.idc {
        background-color: #fafafa;
        border: 1px solid $border-color;
        margin: 0px !important;

        > div {
            margin: 0;
            padding-left: 38px;
            padding-right: 25px;
            padding-bottom: 25px;

            &#IDCommentsHead {
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 0;
            }
        }

        #IDCommentsNoCommentsDiv {
            padding: 18px 20px;
            margin-top: 20px;
            margin-bottom: 25px;
            width: 100%;

            a {
                color: #ed3123;
                text-decoration: none;
            }
        }

        .idc-c-b {
            @include inline-block(top);
            padding-top: 0 !important;
        }

        .idc-c-m.idc-signup {
            @include inline-block(top);
            width: 100%;
        }

        .idc-c-m .idc-column {
            width: 50% !important;
        }

        #IDCommentsNewThread {
            margin-bottom: 0;
        }

        > div {
            padding-left: 25px;
            padding-right: 25px;
        }

        #IDCommentsHead h3 {
            font-size: 1.5em !important;
            font-weight: bold !important;
            color: #000000 !important;
            background-color: #e0e0e0 !important;
            margin-top: 0;
            margin-bottom: 0;
            padding: 22px 25px;

            span {
                font-weight: bold;
            }
        }

        #IDCColumnName {
            padding-right: 10px;
        }

        #IDCColumnEmailLabel,
        #IDCColumnEmailReply {
            padding-left: 10px;
        }

        #IDCColumnName,
        #IDCColumnEmailReply {
            input {
                margin-right: 0;
                width: 100%;
            }
        }

        #IDCommentsNewThreadCover {
            padding-left: 25px;
            padding-right: 25px;

            h3 {
                color: #052868;
                font-size: 14px;
                margin-top: 25px !important;
                margin-bottom: 15px;
                font-weight: bold;
            }
        }

        .idc-columns {
            margin-bottom: 5px;
        }

        #IDCColumnURL,
        #IDCColumnURLLabel,
        #IDCColumnURLReply,
        #IDCColumnURLReplyLabel,
        #IDCPostNav,
        #IDCPostNavReply {
            display: none;
        }

        .idc-btn_l,
        .idc-btn_s,
        .idc-btn_l-secondary {
            @include facilicom-button();
            margin-left: 0 !important;
            margin-top: 10px !important;
            padding: 5px 25px !important;
            font-family: $body-font-family;

            span {
                display: none;
            }

            span.idc-r {
                display: block;
                background: none;
                padding-right: 0;
            }

            strong {
                font-weight: normal !important;
            }
        }

        .idc-text  {
            padding: 8px 10px;
        }

        .idc-text_noresize  {
            padding: 10px;
        }

        .idc-text,
        .idc-text_noresize {
            background: $white;
            border: 1px solid #d5d4d4;
            -webkit-box-shadow: $input-box-shadow;
            box-shadow: $input-box-shadow;
        }

        .idc-text_noresize {
            width: 100% !important;
        }
    }

    div.idc-foot {
        display: none;
    }
}
