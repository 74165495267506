blockquote::before,
blockquote::after {
    content: '"';
}

blockquote {
    font-weight: bold;
    display: block;
    margin: 0;
    padding: 0;
    padding-top: 6px;
    padding-bottom: 12px;
    margin-left: 28px;
    margin-right: 28px;
}

blockquote.streamer_left {
    float: left;
    width: 35%;
    margin-left: 28px;
    margin-right: 50px;
}

blockquote.streamer_right {
    float: right;
    width: 35%;
    margin-left: 50px;
    margin-right: 28px;
}

blockquote br {
    display: none;
} 
