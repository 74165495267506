.popout {
    max-width: 380px;
    padding: 30px;
    margin: 40px auto;
    background-color: white;
    box-shadow: 0 0 5px 5px rgba(black, .05);

    form {
        margin-bottom: 20px;
    }

    .recover {
        padding: 10px 0;
        float: right;
    }

    a {
        text-decoration: underline;
        color: #002469;
    }

    p {
        margin: 20px 0;
    }

    h2 {
        font-size: 24px;
        color: #e52a1f;
    }
/*

    .required_fields_text {
        display: none;
    }*/

    .btn,
    .submit_field input {
        border: 0;
        background-color: #e0e0e0;
        padding: 10px 20px;

        &:hover {
            background-color: #e0e0e0;
        }
    }
}

