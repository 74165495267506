// Main Facilicom Division Turbine stylesheet
// Import this stylesheet from your division site stylesheet.

// Make sure the charset is set appropriately
@charset 'UTF-8';

$base-font-size: 15px;

// Needs to be OUT of .content
@import 'vendor/jquery.fancybox';
@import 'vendor/foundation/normalize';
@import 'vendor/foundation/foundation/components/global';
@import 'vendor/foundation/foundation/components/tooltips';
@import 'vendor/foundation/foundation/components/visibility';

@import 'helpers/variables';
@import 'helpers/mixins';
@import 'helpers/sprites';
@import 'generated/icon';
@import 'generated/inline-images';

html,
body {
    font-size: $base-font-size;
    position: static;
}

@import 'pages/aeroplane';

// The wrapper is added, so the styles don't conflict with the CMS
.content {
    font-family: $body-font-family;
    color: $medium-dark-gray;

    @include respond-min($large-bp) {
        overflow: hidden;
    }

    @import 'vendor/foundation/foundation';

    @import 'layout/base';
    @import 'layout/header';
    @import 'layout/body';
    @import 'layout/sidebar';
    @import 'layout/footer';

    @import 'pages/aeroplane';
    @import 'pages/newsoverview';
    @import 'pages/foodoverview';
    @import 'pages/discountoverview';
    @import 'pages/contact';
    @import 'pages/home';
    @import 'pages/search';
    @import 'pages/shirtoffer';
    @import 'pages/discussionoverview';
    @import 'pages/videooverview';

    @import 'components/navigation/base';
    @import 'components/navigation/header';
    @import 'components/navigation/main';
    @import 'components/navigation/footer';

    @import 'components/forms/base';
    @import 'components/forms/search';

    @import 'components/contentblocks/main';
    @import 'components/contentblocks/sidebar';
    @import 'components/contentblocks/homebanners';
    @import 'components/contentblocks/youtube';
    @import 'components/contentblocks/pagebanner';

    @import 'components/brandbox';
    @import 'components/filter';
    @import 'components/tinymcecontent';
    @import 'components/intensedebate';

    @import 'components/user/user';
}

@import 'components/tooltip';
