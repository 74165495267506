// Footer styling
footer {
    // Double border
    @include x-rem(padding, 0 1.25);
    @include single-box-shadow(0, -1px, 0, 0, $light-gray);
    border-top: 1px solid $white;
}

// Desktop
@include respond-min($large-bp) {
    footer {
        padding: 0;
    }
}
