@charset 'utf-8';

@font-face {
    font-family: 'icon';
    src: url('/assets/fonts/icon.eot');
    src: url('/assets/fonts/icon.eot?#iefix') format('eot'),
        url('/assets/fonts/icon.woff2') format('woff2'),
        url('/assets/fonts/icon.woff') format('woff'),
        url('/assets/fonts/icon.ttf') format('truetype'),
        url('/assets/fonts/icon.svg#icon') format('svg');
    font-weight: normal;
    font-style: normal;
}

$icon-apps: 'apps' '\EA01';
$icon-arrow-down: 'arrow-down' '\EA02';
$icon-arrow_dropdown_gray: 'arrow_dropdown_gray' '\EA03';
$icon-arrow_menu_blue: 'arrow_menu_blue' '\EA04';
$icon-arrow_menu_gray: 'arrow_menu_gray' '\EA05';
$icon-arrow_right_gray: 'arrow_right_gray' '\EA06';
$icon-arrow_share: 'arrow_share' '\EA07';
$icon-brandbox_jumper: 'brandbox_jumper' '\EA08';
$icon-brandbox_jumper_active: 'brandbox_jumper_active' '\EA09';
$icon-brandbox_jumper_hover: 'brandbox_jumper_hover' '\EA0A';
$icon-brandbox_next: 'brandbox_next' '\EA0B';
$icon-brandbox_next_small: 'brandbox_next_small' '\EA0C';
$icon-brandbox_prev: 'brandbox_prev' '\EA0D';
$icon-brandbox_prev_small: 'brandbox_prev_small' '\EA0E';
$icon-bubble: 'bubble' '\EA0F';
$icon-bubbles2: 'bubbles2' '\EA10';
$icon-discount: 'discount' '\EA11';
$icon-discussion: 'discussion' '\EA12';
$icon-discussion_blue: 'discussion_blue' '\EA13';
$icon-discussion_gray: 'discussion_gray' '\EA14';
$icon-discussion_reply: 'discussion_reply' '\EA15';
$icon-download: 'download' '\EA16';
$icon-email: 'email' '\EA17';
$icon-email_actueel: 'email_actueel' '\EA18';
$icon-email_white: 'email_white' '\EA19';
$icon-facebook: 'facebook' '\EA1A';
$icon-facebook2: 'facebook2' '\EA1B';
$icon-facebook_actueel: 'facebook_actueel' '\EA1C';
$icon-healthy_food: 'healthy_food' '\EA1D';
$icon-home: 'home' '\EA1E';
$icon-linkedin: 'linkedin' '\EA1F';
$icon-linkedin_actueel: 'linkedin_actueel' '\EA20';
$icon-login: 'login' '\EA21';
$icon-magnifyglass_15x15: 'magnifyglass_15x15' '\EA22';
$icon-magnifyglass_22x22: 'magnifyglass_22x22' '\EA23';
$icon-menu_medium: 'menu_medium' '\EA24';
$icon-menu_small: 'menu_small' '\EA25';
$icon-minus: 'minus' '\EA26';
$icon-paragraph-left: 'paragraph-left' '\EA27';
$icon-phone: 'phone' '\EA28';
$icon-phone_white: 'phone_white' '\EA29';
$icon-plus: 'plus' '\EA2A';
$icon-poll: 'poll' '\EA2B';
$icon-radiobutton: 'radiobutton' '\EA2C';
$icon-radiobutton_checked: 'radiobutton_checked' '\EA2D';
$icon-redo2: 'redo2' '\EA2E';
$icon-rss: 'rss' '\EA2F';
$icon-rss2: 'rss2' '\EA30';
$icon-search: 'search' '\EA31';
$icon-search_medium: 'search_medium' '\EA32';
$icon-search_small: 'search_small' '\EA33';
$icon-topbar_medium: 'topbar_medium' '\EA34';
$icon-topbar_small: 'topbar_small' '\EA35';
$icon-twitter: 'twitter' '\EA36';
$icon-twitter_actueel: 'twitter_actueel' '\EA37';
$icon-up: 'up' '\EA38';
$icon-video: 'video' '\EA39';
$icon-youtube: 'youtube' '\EA3A';
$icon-youtube_actueel: 'youtube_actueel' '\EA3B';


$icon: $icon-apps $icon-arrow-down $icon-arrow_dropdown_gray $icon-arrow_menu_blue $icon-arrow_menu_gray $icon-arrow_right_gray $icon-arrow_share $icon-brandbox_jumper $icon-brandbox_jumper_active $icon-brandbox_jumper_hover $icon-brandbox_next $icon-brandbox_next_small $icon-brandbox_prev $icon-brandbox_prev_small $icon-bubble $icon-bubbles2 $icon-discount $icon-discussion $icon-discussion_blue $icon-discussion_gray $icon-discussion_reply $icon-download $icon-email $icon-email_actueel $icon-email_white $icon-facebook $icon-facebook2 $icon-facebook_actueel $icon-healthy_food $icon-home $icon-linkedin $icon-linkedin_actueel $icon-login $icon-magnifyglass_15x15 $icon-magnifyglass_22x22 $icon-menu_medium $icon-menu_small $icon-minus $icon-paragraph-left $icon-phone $icon-phone_white $icon-plus $icon-poll $icon-radiobutton $icon-radiobutton_checked $icon-redo2 $icon-rss $icon-rss2 $icon-search $icon-search_medium $icon-search_small $icon-topbar_medium $icon-topbar_small $icon-twitter $icon-twitter_actueel $icon-up $icon-video $icon-youtube $icon-youtube_actueel;

@mixin icon($name) {
    &:before {
        @include icon-style($name);
        @content;
    }
}

@mixin icon-after($name) {
    &:after {
        @include icon-style($name);
        @content;
    }
}

@mixin icon-style($name) {
    @each $icon in $icon {
        $n: index($icon, $name);
        @if $n != null {
            @include  icon-content($icon);
        }
    }
    font-family: 'icon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin icon-content($icon) {
    content: nth($icon, 2);
}
