.sidebarcontentblocks {
    @include grid-row();
    margin-top: 20px;

    .contentblock {
        $column-gutter: 0;
        @include grid-column(12);
        margin-bottom: 30px;

        .title.text {
            padding: 15px 0;
            background: $white;
        }

        &.firstcb {
            .title.text {
                background: rgba($white, .75);
                padding: 15px 20px;
            }
        }

        h2 {
            margin-bottom: 0;
        }
    }
}

.home .sidebarcontentblocks .contentblock.firstcb .title {
    padding: 0 0 15px;
}

.sidebar {
    padding: 0 20px;

    // Standard banner
    .bannerimagetextlabel,
    .chatboxbanner {
        .photo,
        .text {
            background: $light-gray;
            color: $heavy-dark-gray;
        }

        .title {
            overflow: visible;
        }

        .text.richtext {
            padding: 20px 20px 0;

            p {
                margin-top: 0;
            }
        }
        // Call to action
        .label {
            @include x-rem(padding, 1 1.3 1);

            a {
                @include facilicom-button;
            }
        }
    }

    // Composite form (for newsletter subscription
    .compositeform {
        .text > p:last-child {
            padding-bottom: 0;

        }

        .text,
        form {
            @include x-rem(padding-left, 1.3);
            @include x-rem(padding-right, 1.3);
            padding-top: 1.3em;
            display: block;
            background: $light-gray;
        }

        form {
            @include x-rem(padding-bottom, 1.3);

            // Form results
            .article-content {
                .contentblock {
                    float: none;
                    margin-bottom: 0;
                }

            }
        }

        .button,
        .formfield input {
            width: 100%;
        }

        .formfield input {
            @include x-rem(margin-bottom, .666);
        }
    }

    .pillar {
        position: relative;

        .label {
            position: absolute;
            bottom: 15px;
            left: 20px;
            padding-right: 40px;
            width: 100%;

            a {
                @include facilicom-button();
            }
        }
    }

    .contentblock.receptbanner {
        border-left: 30px solid;
        border-left-color: #9aba09;
        position: relative;
        height: 100%;

        .icon {
            background: url('/assets/images/icons/healthy_food.png') no-repeat;
            height: 19px;
            width: 19px;
            display: inline-block;
            position: absolute;
            top: 50%;
            left: -25px;
        }

        .image {
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: $white;
            overflow: hidden;

            img {
                max-width: none;
                width: 100%;
            }
        }

        .text {
            background: #0c2b6f;
            background: rgba(#0c2b6f, .5);
            width: 100%;
            padding: 10px 20px 20px;
            color: $white;
            font-size: 16px;

            h2 {
                color: $white;
                font-size: 20px;
                font-weight: bold;
                margin: 0;
                margin-bottom: 15px;
                line-height: 22px;
            }
        }

        .link-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
        }
    }
}


@media #{$medium-only} {
    .sidebarcontentblocks .contentblock {
        $column-gutter: 0;
        @include grid-column(6);
        @include inline-block(top);
        float: none;

        &.oddcb {
            padding-right: 20px;
        }

        &.pillar {
            &.oddcb .label {
                padding-right: 60px;
            }
        }
    }
}

@include respond-min($large-bp) {
    .sidebarcontentblocks {
        margin-top: 0;
    }
}
