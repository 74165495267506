// Search results
.main-content > .search {
    a {
        text-decoration: none;
    }

    .query_results_label {
        background: $light-gray;
        padding: 20px;

        .query {
            font-size: larger;
            color: $primary-color;
        }
    }

    h1 {
        background: $white;
        background: rgba($white, .75);
        padding: 15px 20px;
    }

    .searchresults {
        background: $white;
    }

    .searchresult {
        @include grid-row(nested);
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        margin-top: -1px;

        h2 {
            font-size: larger;
            font-weight: normal;
            margin-top: 15px;
            margin-bottom: 5px;
        }

        & > .photo-and-label {
            width: 85px;
            margin-right: 20px;
            float: left;
            border-left: 1px solid $white;
            border-top: 1px solid $white;

            .photo {
                position: relative;
                width: 85px;
                height: 56px;
                display: table;

                img {
                    width: 100%;
                }
            }

            .no-image {
                vertical-align: middle;
                display: table-cell;
                text-align: center;

                img {
                    @include opacity(.3);
                    width: 90%;
                }
            }

            .category {
                width: 100%;
            }
        }

        @media #{$small-only} {
            h2 {
                margin: 10px 20px;
            }

            & > .photo-and-label {
                margin-left: 20px;
                margin-bottom: 20px;
            }
        }

        .category {
            background: $light-gray;
            border-top: 1px solid $white;
            border-bottom: 1px solid $white;
            padding: 10px 20px;
            overflow: hidden;
            white-space: nowrap;
            font-size: smaller;
        }

        & > .searchresulttext {
            padding: 0 20px;
            margin: 0;
        }
    }
}

// Popular questions
.popular {
    background-color: $light-gray;
    counter-reset: item;
    list-style-type: none;
    list-style-position: outside;
    margin: 0;

    li {
        position: relative;
        color: $primary-color;
        border-bottom: 1px solid $white;

        a {
            padding: 10px 20px 10px 40px;
            display: block;
            font-size: 15px;
            text-decoration: none;

            &::before {
                font-size: 20px;
                display: block;
                position: absolute;
                left: 16px;
                top: 6px;
                content: counter(item) ' ';
                counter-increment: item;
            }
        }

        &:hover {
            background-color: #e2e2e2;
        }
    }
}

@include respond-min($medium-bp) {
    .main-content > .search {
        .searchresult {
            border: 1px solid $border-color;

            & > .photo-and-label {
                width: 140px;

                .photo {
                    width: 140px;
                    height: 93px;
                }
            }

            & > .searchresulttext {
                padding-left: 20px;
                padding-right: 20px;
            }

            .category {
                font-size: 15px;
            }
        }
    }
}
