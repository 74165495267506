// General menu styles

// Mobile style
.foldout_menu {
    ul {
        @include reset-list-style;
    }

    ul, li {
        margin: 0;
        padding: 0;
    }

    a {
        @include inline-block(top);
        @include x-rem(height, 2.933);
    }

    // Level 1
    & > ul,
    .main_menu-wrapper > ul,
    & > section > ul {
        display: none;

        & > li {
            // Level 2
            & > ul {
                display: none;
            }
        }
    }
}

// Desktop style
@include respond-min($large-bp) {
    .foldout_menu {
        a {
            height: auto;
        }

        // Level 1
        & > ul,
        .main_menu-wrapper > ul,
        & > div > section > ul {
            display: inline-block;
            position: relative;

            & > li {
                @include inline-block(top);

                & > a {
                    padding: 0;
                }

                & > ul {
                    width: 100%;
                    left: 0;
                }

                // Level 2
                & > ul,
                & > div.pair {
                    position: absolute;
                    background-color: $light-gray;
                }
            }
        }
    }
}
