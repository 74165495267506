// Filter style

.filters {
    margin-bottom: 20px;

    .arrow {
        @include icon(arrow_menu_blue);
        @include inline-block(middle);
        @include x-rem(margin-left, .5);
    }

    .category_filter_wrapper {
        display: none;
        padding-top: 1px;
        z-index: 9999;

        .clearfix {
            @include clearfix;
        }
    }

    li.filter-open,
    li:hover {
        .category_filter_wrapper {
            display: block;
        }
    }
}

@media #{$small-only} {
    .filters {
        padding: 0 20px;

        .button.dropdown {
            width: 100% !important;
            margin-bottom: 0;
        }

        ul {
            @include reset-list-style;
            margin: 0;
        }
        // Make filters look like <select>
        & > ul {
            display: none;
            margin-top: -1px;
            border: 0;

            &.filter-open {
                display: block;
            }

            &::after {
                border: 0;
            }

            & > li {
                position: relative;
                width: auto;
                background-color: $white;
                border: 1px solid #d5d4d4;
                border-top-width: 0;

                &:first-child {
                    border-top: 1px solid #d5d4d4;
                }

                &:hover {
                    background-color: $medium-gray;
                }

                & > a {
                    display: block;
                    padding: 10px 15px;
                }

                & > .active {
                    a {
                        background-color: #e2e2e2;
                    }
                }

                & ul > li {
                    background: $medium-gray;

                    & > a {
                        display: block;
                        border-top: 1px solid $border-color;
                        padding: 10px 15px;
                        margin-left: 5px;
                        background-color: $white;

                        &:hover {
                            background-color: $medium-gray;
                        }
                    }
                }
            }
        }
    }
}

@include respond-min($medium-bp) {
    .filters {
        & > ul {
            width: 100%;
            display: table;

            & > li {
                display: table-cell;
                text-align: center;
                border-left: 1px solid #d6d6d6;

                &:hover {
                    border-left-color: #e2e2e2;
                }

                &:first-child {
                    border-left-color: $light-gray;
                    border-radius: 5px 0 0 5px;
                    & > a {
                        border-radius: 5px 0 0 5px;
                        border-left-color: $light-gray;
                    }
                }

                &:last-child {
                    border-right: 1px solid $light-gray;
                    border-radius: 0 5px 5px 0;
                    & > a {
                        border-radius: 0 5px 5px 0;
                    }
                }

                & > a {
                    padding: 4px 20px 0;
                    display: block;
                    background-color: $light-gray;
                    margin-bottom: 0;
                    border-top: 1px solid $light-gray;
                    border-bottom: 4px solid $light-gray;
                    text-decoration: none;

                    &:hover {
                        border-top: 1px solid #e2e2e2;
                        border-bottom: 1px solid #e2e2e2;
                        background-color: $white;
                        padding-bottom: 3px;
                    }
                }

                &.active, &.filter-open, &.question:hover {
                    & > a {
                        background-color: #e2e2e2;
                        border-width: 0;
                        border-top: 1px solid #e2e2e2;
                        border-bottom: 4px solid #c7c7c7;
                        padding-bottom: 0px;
                    }
                }

                &.filter-open, &.question:hover {
                    & > a {
                        border-bottom-color: #e2e2e2;
                    }
                }

                &.question:hover {
                    border-left: 1px solid #d6d6d6;
                }
            }
        }

        .category_filter_wrapper {
            padding-right: 20px;
            position: absolute;
            left: 0;
            width: 100%;
        }

        .category_filter {
            @include reset-list-style;
            height: auto;
            background-color: #e0e0e0;
            padding: 20px 20px 30px;
            text-align: left;
            border-bottom: 5px solid $white;

            & > li {
                width: 33.333%;
                float: left;

                & > a {
                    display: block;
                    color: $medium-dark-gray;
                    text-decoration: none;
                    line-height: 40px;

                    &:hover {
                        color: $primary-color;
                        text-decoration: inderline;
                    }
                }
            }
        }

        li.filter-open,
        li.question:hover {
            @include single-box-shadow(0, 1px, 0, 0, #e2e2e2);
        }
    }
}
